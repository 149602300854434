import { FC, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'clsx';

import { ArrowIcon, SortIcon } from '@/assets/img/icons';
import { Typography } from '@/components';

import s from './styles.module.scss';

export interface IOption {
  value: string | number;
  text?: string;
}

export interface SelectProps {
  defaultOption: IOption;
  options: IOption[];
  onSelect?: (value: IOption) => void;
  className?: string;
}

export const Select: FC<SelectProps> = ({ defaultOption, options, onSelect, className }) => {
  const selectRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [currentOption, setCurrentOption] = useState(defaultOption);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const handleCloseSelect = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleSelect = useCallback(
    (option: IOption) => {
      setIsOpen(false);
      setCurrentOption(option);
      if (onSelect) {
        onSelect(option);
      }
    },
    [onSelect],
  );

  const handleClickOutside = useCallback(
    (event: any) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        handleCloseSelect();
      }
    },
    [handleCloseSelect, selectRef],
  );

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef, handleToggle, handleClickOutside]);

  return (
    <div ref={selectRef} className={cn(s.select_wrapper, isOpen && s.active, className)}>
      <div
        className={cn(s.select)}
        onClick={handleToggle}
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
      >
        <SortIcon />
        <Typography className={s.sort_by} type="body1" spacing={0.05} height={20} weight="normal">
          {currentOption.text}
        </Typography>
        <ArrowIcon className={s.icon} />
      </div>
      <div className={cn(s.options, isOpen && s.active)}>
        {options.map((option) => (
          <div
            key={option.value}
            className={s.option}
            role="button"
            tabIndex={-1}
            onKeyDown={handleToggle}
            onClick={() => handleSelect(option)}
          >
            <Typography
              type="body2"
              spacing={0.05}
              weight={option.value === currentOption.value ? 'bold' : 'normal'}
            >
              {option.text}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};
