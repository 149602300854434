import type { StateWithUIState, UIState } from '@/types';

/* eslint-disable @typescript-eslint/no-explicit-any */
export default {
  getUI: (state: StateWithUIState): UIState => state.ui,
  getProp:
    (propKey: string) =>
    (state: StateWithUIState): any =>
      state.ui[propKey],
};
