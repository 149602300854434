import BigNumber from 'bignumber.js';
import { all, call, put, select, takeLatest } from 'typed-redux-saga';

import { ContractsNames } from '@/config';
import apiActions from '@/store/api/actions';
import { getLockupDataSaga } from '@/store/lockup/sagas/getLockupDataSaga';
import userActionTypes from '@/store/user/actionTypes';
import { approveSaga } from '@/store/user/sagas/approve';
import { getTokenBalanceSaga } from '@/store/user/sagas/getTokenBalance';
import { getUserLockupDataSaga } from '@/store/user/sagas/getUserLockupData';
import userSelector from '@/store/user/selectors';
import { Erc20Abi, VeXb3TokenAbi } from '@/types';
import { notify, toDecimals } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { increaseLockupAmount } from '../actions';
import actionTypes from '../actionTypes';

export function* increaseLockupAmountSaga({
  type,
  payload: { web3Provider, amount },
}: ReturnType<typeof increaseLockupAmount>) {
  yield* put(apiActions.request(type));

  const [veXb3TokenAbi, veXb3TokenAddress] = getContractDataByHisName(ContractsNames.veXb3Token);
  const veXbfContract: VeXb3TokenAbi = yield new web3Provider.eth.Contract(
    veXb3TokenAbi,
    veXb3TokenAddress,
  );

  const [tokenAbi, tokenAddress] = getContractDataByHisName(ContractsNames.xb3Token);
  const tokenContract: Erc20Abi = yield new web3Provider.eth.Contract(tokenAbi, tokenAddress);

  try {
    const userAddress = yield* select(userSelector.getProp('address'));
    const amountWithDecimals = toDecimals(amount);
    const allowance = yield* call(
      tokenContract.methods.allowance(userAddress, veXb3TokenAddress).call,
    );

    if (new BigNumber(allowance).isLessThan(amountWithDecimals)) {
      yield* call(approveSaga, {
        type: userActionTypes.APPROVE,
        payload: {
          web3Provider,
          contract: ContractsNames.xb3Token,
          spenderAddress: veXb3TokenAddress,
          amount,
        },
      });
    }

    // CHECK IF CAN SEND
    yield* call(veXbfContract.methods.increaseAmount(amountWithDecimals).estimateGas, {
      from: userAddress,
    });

    yield* call(veXbfContract.methods.increaseAmount(amountWithDecimals).send, {
      from: userAddress,
    });

    const requests = [
      getLockupDataSaga({
        type: actionTypes.GET_LOCKUP_DATA,
        payload: undefined,
      }),
      getUserLockupDataSaga({
        type: userActionTypes.GET_USER_LOCKUP_DATA,
        payload: { web3Provider, address: userAddress },
      }),
      getTokenBalanceSaga({
        type: userActionTypes.GET_TOKEN_BALANCE,
        payload: { web3Provider, address: userAddress },
      }),
    ];

    yield* all(requests);

    yield* put(apiActions.success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    notify.error('Something may go wrong. Please check your inputs.');
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.INCREASE_LOCKUP_AMOUNT, increaseLockupAmountSaga);
}
