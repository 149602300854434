import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import BigNumber from 'bignumber.js';
import cn from 'clsx';

import { Button, Expander, InfoPopover, Input, Typography } from '@/components';
import { useShallowSelector } from '@/hooks';
import { ContractAddress } from '@/pages/Hive/components/ContractAddress';
import { useWalletConnectorContext } from '@/services';
import { depositCvxCrv, depositLp, withdraw } from '@/store/hive/actions';
import hiveActionTypes from '@/store/hive/actionTypes';
import uiSelector from '@/store/ui/selectors';
import { RequestStatus } from '@/types';
import { convertBigNumbersToReadable } from '@/utils';

import s from './styles.module.scss';

export interface StakeCardProps {
  userLpTokenBalance: string;
  userCrvTokenBalance: string;
  userCvxCrvTokenBalance: string;
  apr: string | number;
  tvl: string | number;
  slug: string;
  cardLogo: ReactElement;
  popoverContent: ReactElement | string;
  depositSeparateTokens?: boolean;
  className?: string;
  rewardCrvTokenAddress: string;
  rewardXb3TokenAddress: string;
  address: string;
  lpTokenAddress: string;
  userDeposit: string;
  lpPriceInUsd: string;
}

export const StakeCard: FC<StakeCardProps> = ({
  className,
  userLpTokenBalance,
  userCrvTokenBalance,
  userCvxCrvTokenBalance,
  apr,
  tvl,
  slug,
  cardLogo,
  popoverContent,
  depositSeparateTokens,
  rewardCrvTokenAddress,
  rewardXb3TokenAddress,
  address,
  lpTokenAddress,
  userDeposit,
  lpPriceInUsd,
}) => {
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();

  const {
    [hiveActionTypes.DEPOSIT_LP]: depositLpRequestStatus,
    [hiveActionTypes.DEPOSIT_CVX_CRV]: depositCvxCrvRequestStatus,
    [hiveActionTypes.WITHDRAW]: withdrawRequestStatus,
  } = useShallowSelector(uiSelector.getUI);

  const [depositCrvAmount, setDepositCrvAmount] = useState('');
  const [depositCvxCrvAmount, setDepositCvxCrvAmount] = useState('');

  const [depositLpAmount, setDepositLpAmount] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');

  const userWithdrawBalance = useMemo(
    () => (userDeposit ? new BigNumber(userDeposit).toFixed(2) : '0'),
    [userDeposit],
  );
  const userDepositInUsd = useMemo(
    () => (userDeposit ? new BigNumber(userDeposit).multipliedBy(lpPriceInUsd).toFixed(2) : '0'),
    [lpPriceInUsd, userDeposit],
  );

  const isDepositCvxCrvButtonDisabled = useMemo(
    () =>
      (new BigNumber(depositCvxCrvAmount).isGreaterThan(userCvxCrvTokenBalance) &&
        new BigNumber(depositCrvAmount).isGreaterThan(userCrvTokenBalance)) ||
      (depositCrvAmount === '' && depositCvxCrvAmount === ''),
    [depositCrvAmount, depositCvxCrvAmount, userCrvTokenBalance, userCvxCrvTokenBalance],
  );
  const isDepositLpButtonDisabled = useMemo(
    () => new BigNumber(depositLpAmount).isGreaterThan(userLpTokenBalance) || !depositLpAmount,
    [depositLpAmount, userLpTokenBalance],
  );
  const isWithdrawButtonDisabled = useMemo(
    () => new BigNumber(withdrawAmount).isGreaterThan(userWithdrawBalance) || !withdrawAmount,
    [userWithdrawBalance, withdrawAmount],
  );

  const isDepositLpInProcess = useMemo(
    () => depositLpRequestStatus === RequestStatus.REQUEST,
    [depositLpRequestStatus],
  );
  const isDepositCvxCrvInProcess = useMemo(
    () => depositCvxCrvRequestStatus === RequestStatus.REQUEST,
    [depositCvxCrvRequestStatus],
  );
  const isWithdrawInProcess = useMemo(
    () => withdrawRequestStatus === RequestStatus.REQUEST,
    [withdrawRequestStatus],
  );

  const handleDepositLp = () => {
    dispatch(depositLp({ web3Provider: walletService.Web3(), amount: depositLpAmount }));
  };
  const handleDepositCvxCrv = () => {
    dispatch(
      depositCvxCrv({
        web3Provider: walletService.Web3(),
        crvTokenAmount: depositCrvAmount !== '' ? depositCrvAmount : '0',
        cvxCrvTokenAmount: depositCvxCrvAmount !== '' ? depositCvxCrvAmount : '0',
      }),
    );
  };
  const handleWithdraw = () => {
    dispatch(
      withdraw({
        web3Provider: walletService.Web3(),
        vaultAddress: address,
        amount: withdrawAmount,
      }),
    );
  };

  useEffect(() => {
    if (depositLpRequestStatus === RequestStatus.SUCCESS) {
      setDepositLpAmount('');
    }
  }, [depositLpRequestStatus]);
  useEffect(() => {
    if (depositCvxCrvRequestStatus === RequestStatus.SUCCESS) {
      setDepositCrvAmount('');
      setDepositCvxCrvAmount('');
    }
  }, [depositCvxCrvRequestStatus]);
  useEffect(() => {
    if (withdrawRequestStatus === RequestStatus.SUCCESS) {
      setWithdrawAmount('');
    }
  }, [withdrawRequestStatus]);

  return (
    <Expander
      background="blue"
      header={
        <div className={s.header}>
          <div className={s.headerToken}>
            {cardLogo}
            <Typography weight="medium" type="body1">
              {slug} Vault
            </Typography>
            <InfoPopover contentClassName={s.popover} align="center">
              {popoverContent}
            </InfoPopover>
          </div>
          <div className={s.headerStatistic}>
            <div className={s.headerStatItem}>
              <Typography type="label1">Average APR</Typography>
              <Typography type="h2">{convertBigNumbersToReadable(apr)}%</Typography>
            </div>
            <div className={s.headerStatItem}>
              <Typography type="label1">My deposits</Typography>
              <Typography type="h2">{convertBigNumbersToReadable(userDepositInUsd)}$</Typography>
            </div>
            <div className={s.headerStatItem}>
              <Typography type="label1">Total Value Locked</Typography>
              <Typography type="h2">{convertBigNumbersToReadable(tvl)}$</Typography>
            </div>
          </div>
        </div>
      }
      className={cn(s.stakeCard, className)}
    >
      {depositSeparateTokens && (
        <>
          <div className={s.multipleInputs}>
            <Input
              value={depositCrvAmount}
              onChange={setDepositCrvAmount}
              onlyNumbers
              className={s.input}
              label="DEPOSIT CRV"
              labelEnd="-or-"
              labelEndClassName={s.crvInputLabelEnd}
              placeholder="0.00"
            />
            <Input
              value={depositCvxCrvAmount}
              onChange={setDepositCvxCrvAmount}
              onlyNumbers
              className={s.input}
              label="DEPOSIT cvxCRV"
              placeholder="0.00"
            />
          </div>
          <Button
            disabled={isDepositCvxCrvButtonDisabled}
            loading={isDepositCvxCrvInProcess}
            onClick={handleDepositCvxCrv}
          >
            Deposit
          </Button>
        </>
      )}

      <Input
        value={depositLpAmount}
        onChange={setDepositLpAmount}
        onlyNumbers
        className={s.input}
        label="DEPOSIT cvxCRV LP TOKENS"
        labelEnd={
          <Typography spacing={0.1} className={s.inputLabelBalance} type="body2">
            Balance <span>{new BigNumber(userLpTokenBalance).toFixed(2)}</span>
          </Typography>
        }
        placeholder="0.00"
      />
      <Button
        disabled={isDepositLpButtonDisabled}
        loading={isDepositLpInProcess}
        onClick={handleDepositLp}
      >
        Deposit
      </Button>

      <Input
        value={withdrawAmount}
        onChange={setWithdrawAmount}
        onlyNumbers
        className={s.input}
        label="Withdraw"
        labelEnd={
          <Typography spacing={0.1} className={s.inputLabelBalance} type="body2">
            Balance <span>{userWithdrawBalance}</span>
          </Typography>
        }
        placeholder="0.00"
      />
      <Button
        disabled={isWithdrawButtonDisabled}
        loading={isWithdrawInProcess}
        onClick={handleWithdraw}
      >
        Withdraw
      </Button>

      <Expander
        className={s.additionalInfo}
        header={
          <Typography weight="medium" type="body1">
            Additional Information
          </Typography>
        }
        variant="small"
      >
        <Typography height={35} isUpper type="sub2">
          Contract addresses
        </Typography>
        <ul>
          <ContractAddress title="LP token Address" value={lpTokenAddress} />
          <ContractAddress title="Deposit contract address" value={address} />
          <ContractAddress title="Rewards contract address (CRV)" value={rewardCrvTokenAddress} />
          <ContractAddress title="Rewards contract address (XB3)" value={rewardXb3TokenAddress} />
        </ul>
      </Expander>
    </Expander>
  );
};
