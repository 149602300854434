const GET_IS_USER_REGISTERED = 'GET_IS_USER_REGISTERED';
const REGISTER = 'REGISTER';
const GET_USER_REFERRAL_NETWORK = 'GET_USER_REFERRAL_NETWORK';
const GET_REFERRAL_STATS = 'GET_REFERRAL_STATS';
const GET_MY_REFERRAL_CODE = 'GET_MY_REFERRAL_CODE';
const HIT_REFERRER = 'HIT_REFERRER';

export default {
  GET_IS_USER_REGISTERED,
  REGISTER,
  GET_USER_REFERRAL_NETWORK,
  GET_REFERRAL_STATS,
  GET_MY_REFERRAL_CODE,
  HIT_REFERRER,
};
