import { fork } from 'redux-saga/effects';

import approve from './approve';
import claimMigrationTokensListener from './claimMigrationTokens';
import getCrvTokenBalanceSaga from './getCrvTokenBalance';
import getCvxCrvTokenBalanceSaga from './getCvxCrvTokenBalance';
import getFtmBalance from './getEthBalance';
import getMigrationInfoListener from './getMigrationInfo';
import getTokenBalance from './getTokenBalance';
import getUserBoostLevelSaga from './getUserBoostLevel';
import getUserHiveRewardsSaga from './getUserHiveRewards';
import getUserLockupDataSaga from './getUserLockupData';
import getUserMainInfo from './getUserMainInfo';
import getUserSignatureInfoListener from './getUserSignatureInfo';
import getUserVaultsDataSaga from './getUserVaultsData';
import getUserXb3RewardsSaga from './getUserXb3Rewards';
import signTermsOfUseListener from './signTermsOfUse';

export default function* userSagas() {
  yield fork(getTokenBalance);
  yield fork(getFtmBalance);
  yield fork(getUserMainInfo);
  yield fork(approve);
  yield fork(getUserLockupDataSaga);
  yield fork(getUserBoostLevelSaga);
  yield fork(getUserHiveRewardsSaga);
  yield fork(getUserXb3RewardsSaga);
  yield fork(getUserVaultsDataSaga);
  yield fork(getCrvTokenBalanceSaga);
  yield fork(getCvxCrvTokenBalanceSaga);
  yield fork(getMigrationInfoListener);
  yield fork(claimMigrationTokensListener);
  yield fork(getUserSignatureInfoListener);
  yield fork(signTermsOfUseListener);
}
