import { call, put, select, takeLatest } from 'typed-redux-saga';

import { error, request, success } from '@/store/api/actions';
import { updateUserState } from '@/store/user/reducer';
import userSelector from '@/store/user/selectors';
import { fromDecimals } from '@/utils';

import { getFtmBalance } from '../actions';
import actionTypes from '../actionTypes';

export function* getFtmBalanceSaga({
  type,
  payload: { web3Provider },
}: ReturnType<typeof getFtmBalance>) {
  yield put(request(type));

  try {
    const myAddress = yield* select(userSelector.getProp('address'));
    const ethBalance = yield* call(() => web3Provider.eth.getBalance(myAddress));
    yield put(updateUserState({ ethBalance: fromDecimals(ethBalance, 18) }));

    yield put(success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_FTM_BALANCE, getFtmBalanceSaga);
}
