import { call, put, select, takeLatest } from 'typed-redux-saga';

import { ContractsNames } from '@/config';
import apiActions from '@/store/api/actions';
import userSelector from '@/store/user/selectors';
import { ReferralProgramAbi } from '@/types/contracts/ReferralProgramAbi';
import { notify } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { claimReferralRewards } from '../actions';
import actionTypes from '../actionTypes';

export function* claimReferralRewardsSaga({
  type,
  payload: { web3Provider },
}: ReturnType<typeof claimReferralRewards>) {
  yield* put(apiActions.request(type));

  try {
    const [abi, address] = getContractDataByHisName(ContractsNames.referralProgram);
    const referralProgrammContract: ReferralProgramAbi = yield new web3Provider.eth.Contract(
      abi,
      address,
    );
    const userAddress = yield* select(userSelector.getProp('address'));

    // CHECK IF CAN SEND
    yield* call(referralProgrammContract.methods.claimRewards().estimateGas, {
      from: userAddress,
    });

    yield* call(referralProgrammContract.methods.claimRewards().send, {
      from: userAddress,
    });

    yield* put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    notify.error('Something may go wrong. Please check your inputs.');
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CLAIM_REFERRAL_REWARDS, claimReferralRewardsSaga);
}
