import { createAction } from '@reduxjs/toolkit';

import { HitReferrerAction, RegisterReq, RequestWithWeb3Provider } from '@/types/requests';

import actionTypes from './actionTypes';

export const getIsUserRegistered = createAction<RequestWithWeb3Provider>(
  actionTypes.GET_IS_USER_REGISTERED,
);
export const register = createAction<RegisterReq>(actionTypes.REGISTER);
export const getUserReferralNetwork = createAction(actionTypes.GET_USER_REFERRAL_NETWORK);
export const getReferralStats = createAction(actionTypes.GET_REFERRAL_STATS);
export const getMyReferralCode = createAction(actionTypes.GET_MY_REFERRAL_CODE);
export const hitReferrer = createAction<HitReferrerAction>(actionTypes.HIT_REFERRER);
