import { FC, useEffect, useState } from 'react';

import { CrvLogo } from '@/assets/img/icons';
import { DetailedInfoPopover, LabelSection } from '@/components';
import { Select } from '@/components/Select';
import { useShallowSelector } from '@/hooks';
import hiveSelector from '@/store/hive/selectors';
import ratesSelector from '@/store/rates/selectors';
import userSelector from '@/store/user/selectors';
import { State, UserState } from '@/types';

import { StakeCard, StakeCardProps } from '../StakeCard';

import { filterItems, stakesInfo } from './Stake.helper';

import s from './styles.module.scss';

export const Stake: FC = () => {
  const vaults = useShallowSelector(hiveSelector.getProp('vaults'));
  const { price: lpPriceInUsd } = useShallowSelector(ratesSelector.getProp('crvCvxCrvLp'));
  const { lpTokenBalance, crvTokenBalance, cvxCrvTokenBalance, hiveDeposits } = useShallowSelector<
    State,
    UserState
  >(userSelector.getUser);
  const [stakes, setStakes] = useState<any>([]);

  // TODO: add later interface for StakeCard when connect to smart-contracts
  const handleSortStakes = (sortType: string, stakesArray: Array<any>) => {
    const sortedStakes = [...stakesArray].sort((a, b) => b[sortType] - a[sortType]);
    setStakes(sortedStakes);
  };

  useEffect(() => {
    const finalStakes = vaults.map((vault, index) => ({ ...vault, ...stakesInfo[index] }));
    setStakes(finalStakes);
  }, [vaults]);

  return (
    <section className={s.stake_wrapper}>
      <LabelSection
        icon={<CrvLogo />}
        endContent={<DetailedInfoPopover />}
        title="Stake Curve LP Tokens"
      />
      <Select
        className={s.select}
        defaultOption={filterItems.defaultOption}
        options={filterItems.othersOptions}
        onSelect={(value) => handleSortStakes(value.value as string, stakes)}
      />
      {stakes.map((stake: StakeCardProps, index: number) => (
        <StakeCard
          key={stake.slug}
          className={s.stakeCard}
          {...stake}
          userLpTokenBalance={lpTokenBalance}
          userCrvTokenBalance={crvTokenBalance}
          userCvxCrvTokenBalance={cvxCrvTokenBalance}
          depositSeparateTokens
          userDeposit={hiveDeposits[index]}
          lpPriceInUsd={lpPriceInUsd}
        />
      ))}
    </section>
  );
};
