import { FC, SVGProps } from 'react';

import { CrvLogoSm, CvxIcon, Xb3Logo } from '@/assets/img/icons';
import { RatesState } from '@/types';

interface ClaimableTokenType {
  icon: FC<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  asset: keyof RatesState | 'veXb3';
  grayOut?: boolean;
}
export const claimableTokens: ClaimableTokenType[] = [
  {
    icon: CrvLogoSm,
    asset: 'crv',
    grayOut: true,
  },
  {
    icon: CvxIcon,
    asset: 'cvx',
    grayOut: true,
  },
  {
    icon: Xb3Logo,
    asset: 'xb3',
  },
  {
    icon: Xb3Logo,
    asset: 'veXb3',
  },
];
