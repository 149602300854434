import { call, put, select, takeLatest } from 'typed-redux-saga';

import { referralsApi } from '@/api/referralsApi';
import apiActions from '@/store/api/actions';
import userSelector from '@/store/user/selectors';

import { getMyReferralCode } from '../actions';
import actionTypes from '../actionTypes';
import { updateReferralsState } from '../reducer';

export function* getMyReferralCodeSaga({ type }: ReturnType<typeof getMyReferralCode>) {
  yield* put(apiActions.request(type));

  try {
    const userAddress = yield* select(userSelector.getProp('address'));
    let referralCode = '';
    let shortLink = '';

    try {
      const { data } = yield* call(referralsApi.getReferralCode, userAddress);
      referralCode = data.code;
      shortLink = data.shortLink;
    } catch (error) {
      referralCode = '';
      shortLink = '';
    }

    yield* put(
      updateReferralsState({
        myCode: referralCode,
        shortUrl: shortLink,
      }),
    );

    yield* put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_MY_REFERRAL_CODE, getMyReferralCodeSaga);
}
