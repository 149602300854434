import Web3 from 'web3';

import { chains } from '@/config';
import { mainnetRpc } from '@/config/constants';
import { Chains } from '@/types';

export const getWeb3 = async (chainName: Chains, forceMainnet = false) => {
  const rpcUrl = forceMainnet ? mainnetRpc : chains[Chains[chainName]].network.rpc;
  return new Web3(rpcUrl as string);
};
