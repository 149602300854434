/* eslint-disable @typescript-eslint/no-explicit-any */
import type { RatesState, State } from '@/types';

export default {
  getRates: (state: State): RatesState => state.rates,
  getProp:
    <T extends keyof RatesState>(propKey: T): any =>
    (state: State) =>
      state.rates[propKey],
};
