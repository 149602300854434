import axios from 'axios';

import { camelize } from '@/utils';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  transformResponse: (response) => {
    return camelize(JSON.parse(response));
  },
});
export default api;
