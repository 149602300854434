import { FC } from 'react';
import cn from 'clsx';

import { CalendarIcon, EyeIcon, LinkIcon, PeopleIcon } from '@/assets/img/icons';
import { formatDate } from '@/utils';

import { InfoItem } from '../InfoItem';

import s from './styles.module.scss';

export interface MainInfoProps {
  createdAt: string;
  myCode: string;
  shortLink: string;
  hits: number;
  className?: string;
}

export const MainInfo: FC<MainInfoProps> = ({ className, createdAt, myCode, hits, shortLink }) => {
  return (
    <div className={cn(s.mainInfo, className)}>
      <InfoItem
        valueToCopy={myCode}
        subtitle={myCode || 'Loading...'}
        title="My Referral Code"
        icon={<PeopleIcon />}
        color="green"
      />
      <InfoItem
        valueToCopy={shortLink}
        subtitle={shortLink?.split('https://')[1] || 'Loading...'}
        title="My Referral Link"
        icon={<LinkIcon />}
        color="blue"
      />
      <InfoItem
        subtitle={createdAt ? formatDate(+createdAt) : 'Loading...'}
        title="Date Registred"
        icon={<CalendarIcon />}
        color="purple"
      />
      <InfoItem
        subtitle={hits.toString()}
        title="My Referral Hits"
        icon={<EyeIcon />}
        color="orange"
      />
    </div>
  );
};
