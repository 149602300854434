import { FC } from 'react';
import cn from 'clsx';

import { XB3_DOCS_URL } from '@/config/constants';

import { InfoPopover } from '../InfoPopover';

import s from './styles.module.scss';

export interface DetailedInfoPopoverProps {
  className?: string;
}

export const DetailedInfoPopover: FC<DetailedInfoPopoverProps> = ({ className }) => {
  return (
    <InfoPopover
      align="right"
      contentClassName={s.content}
      className={cn(s.detailedInfoPopover, className)}
    >
      For more detailed information please visit{' '}
      <a className={cn(s.link)} target="_blank" rel="noreferrer noopener" href={XB3_DOCS_URL}>
        docs.xb3.finance
      </a>
      &nbsp;or&nbsp;
      <a className={cn(s.link)} href="//xb3.finance" target="_blank" rel="noreferrer noopener">
        xb3.finance
      </a>
    </InfoPopover>
  );
};
