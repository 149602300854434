import BigNumber from 'bignumber.js';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { ContractsNames } from '@/config';
import { MAX_UINT } from '@/config/constants';
import apiActions from '@/store/api/actions';
import userActionTypes from '@/store/user/actionTypes';
import { updateUserState } from '@/store/user/reducer';
import { approveSaga } from '@/store/user/sagas/approve';
import userSelector from '@/store/user/selectors';
import { ConvexVaultAbi, CurveVaultAbi, Erc20Abi } from '@/types';
import { createContract, fromDecimals, notify, toDecimals } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { depositCvxCrv } from '../actions';
import actionTypes from '../actionTypes';

import { getVaultsDataSaga } from './getVaultsData';

const MIN_MINT_AMOUNT_ERROR_FACTOR = 0.95;

export function* depositCvxCrvSaga({
  type,
  payload: { web3Provider, crvTokenAmount, cvxCrvTokenAmount },
}: ReturnType<typeof depositCvxCrv>) {
  yield* put(apiActions.request(type));

  const { crvTokenBalance, cvxCrvTokenBalance, address } = yield* select(userSelector.getUser);

  const [crvTokenAbi, crvTokenAddress] = getContractDataByHisName(ContractsNames.crvToken);
  const [cvxCrvTokenAbi, cvxCrvTokenAddress] = getContractDataByHisName(ContractsNames.cvxCrvToken);
  const [vaultAbi, vaultAddress] = getContractDataByHisName(ContractsNames.convexVault);
  const [curveVaultAbi, curveVaultAddress] = getContractDataByHisName(ContractsNames.curveVault);

  try {
    const crvTokenContract: Erc20Abi = yield new web3Provider.eth.Contract(
      crvTokenAbi,
      crvTokenAddress,
    );
    const cvxCrvTokenContract: Erc20Abi = yield new web3Provider.eth.Contract(
      cvxCrvTokenAbi,
      cvxCrvTokenAddress,
    );

    const curveVaultContract: CurveVaultAbi = yield createContract(
      curveVaultAbi,
      curveVaultAddress,
      true,
    );
    const vaultContract: ConvexVaultAbi = yield new web3Provider.eth.Contract(
      vaultAbi,
      vaultAddress,
    );

    const allowanceCrv = yield* call(
      crvTokenContract.methods.allowance(address, vaultAddress).call,
    );
    const allowanceCvxCrv = yield* call(
      cvxCrvTokenContract.methods.allowance(address, vaultAddress).call,
    );

    const amountCrvWithDecimals = toDecimals(crvTokenAmount);
    const amountCvxCrvWithDecimals = toDecimals(cvxCrvTokenAmount);

    if (new BigNumber(allowanceCrv).isLessThan(amountCrvWithDecimals)) {
      yield call(approveSaga, {
        type: userActionTypes.APPROVE,
        payload: {
          web3Provider,
          contract: ContractsNames.crvToken,
          spenderAddress: vaultAddress,
          amount: fromDecimals(MAX_UINT),
        },
      });
    }

    if (new BigNumber(allowanceCvxCrv).isLessThan(amountCvxCrvWithDecimals)) {
      yield call(approveSaga, {
        type: userActionTypes.APPROVE,
        payload: {
          web3Provider,
          contract: ContractsNames.cvxCrvToken,
          spenderAddress: vaultAddress,
          amount: fromDecimals(MAX_UINT),
        },
      });
    }

    const minMintAmount = yield* call(
      curveVaultContract.methods.calc_token_amount(
        [amountCrvWithDecimals, amountCvxCrvWithDecimals],
        true,
      ).call,
    );

    // CHECK IF CAN CALL
    yield* call(
      vaultContract.methods.depositUnderlyingTokensFor(
        [amountCrvWithDecimals, amountCvxCrvWithDecimals],
        new BigNumber(minMintAmount).multipliedBy(MIN_MINT_AMOUNT_ERROR_FACTOR).toFixed(0),
        address,
      ).estimateGas,
      { from: address },
    );

    yield* call(
      vaultContract.methods.depositUnderlyingTokensFor(
        [amountCrvWithDecimals, amountCvxCrvWithDecimals],
        new BigNumber(minMintAmount).multipliedBy(MIN_MINT_AMOUNT_ERROR_FACTOR).toFixed(0),
        address,
      ).send,
      { from: address },
    );

    yield* call(getVaultsDataSaga, {
      type: actionTypes.GET_VAULTS_DATA,
      payload: undefined,
    });

    yield* put(
      updateUserState({
        crvTokenBalance: new BigNumber(crvTokenBalance).minus(crvTokenAmount).toString(),
        cvxCrvTokenBalance: new BigNumber(cvxCrvTokenBalance).minus(cvxCrvTokenAmount).toString(),
      }),
    );

    yield* put(apiActions.success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    notify.error('Something may go wrong. Please check your inputs.');
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.DEPOSIT_CVX_CRV, depositCvxCrvSaga);
}
