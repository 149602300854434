import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import cn from 'clsx';

import { MedalIcon, ReferralGreenIcon, Xb3Logo } from '@/assets/img/icons';
import { LabelSection, Subheader, Typography } from '@/components';
import { useShallowSelector } from '@/hooks';
import ratesSelector from '@/store/rates/selectors';
import referralsSelector from '@/store/referrals/selectors';
import userSelector from '@/store/user/selectors';
import { State, UserState } from '@/types';

import s from './Dashboard.module.scss';

const LabelSectionLink: FC<{ to: string }> = ({ to }) => {
  return (
    <Link to={to}>
      <Typography weight="semiBold" type="sub2">
        VIEW DETAILS
      </Typography>
    </Link>
  );
};

const Dashboard: FC = () => {
  const { lockup, xb3Balance, bonusCampaignReward, veXb3Balance } = useShallowSelector<
    State,
    UserState
  >(userSelector.getUser);
  const { price: xb3PriceInUsd } = useShallowSelector(ratesSelector.getProp('xb3'));
  const { price: lpPriceInUsd } = useShallowSelector(ratesSelector.getProp('crvCvxCrvLp'));
  const rates = useShallowSelector(ratesSelector.getRates);

  const { hiveDeposits, hiveRewards: userRewards } = useShallowSelector(userSelector.getUser);
  const totalDeposits = hiveDeposits[0];
  const hiveRewardEarnedInUsd = useMemo(
    () =>
      new BigNumber(new BigNumber(userRewards.xb3).multipliedBy(rates.xb3.price))
        .plus(new BigNumber(userRewards.crv).multipliedBy(rates.crv.price))
        .plus(new BigNumber(userRewards.cvx).multipliedBy(rates.cvx.price))
        .toFixed(2),
    [rates.crv.price, rates.cvx.price, rates.xb3.price, userRewards],
  );

  const referralsStatistic = useShallowSelector(referralsSelector.getProp('statistic'));

  return (
    <div className={s.dashboard_wrapper}>
      <Subheader tokenPrice={xb3PriceInUsd} userBalance={xb3Balance} withBalance={false} />
      <div className="card">
        <LabelSection
          icon={<Xb3Logo />}
          title="XB3 Locked"
          background="pink"
          endContent={<LabelSectionLink to="/xb3/lockup" />}
        />
        <div className={cn(s.section, s.pink)}>
          <div>
            <Typography className={s.label} type="label1">
              XB3 Locked
            </Typography>
            <Typography type="h2" className={s.numbers}>
              {new BigNumber(lockup.lockedBalance).decimalPlaces(4, 1).toString()}
            </Typography>
          </div>
          <div>
            <Typography className={s.label} type="label1">
              VeXB3 Balance
            </Typography>
            <Typography type="h2" className={s.numbers}>
              {new BigNumber(veXb3Balance).toFixed(2)}
            </Typography>
          </div>
          <div>
            <Typography className={s.label} type="label1">
              Locked and Unlocked Rewards (USD)
            </Typography>
            <Typography type="h2" className={s.numbers}>
              ${new BigNumber(lockup.pendingReward).multipliedBy(xb3PriceInUsd).toFixed(2)}
            </Typography>
          </div>
        </div>
        <LabelSection
          icon={<MedalIcon />}
          title="XB3 bonus campaign"
          background="dark"
          endContent={<LabelSectionLink to="/xb3/rewards" />}
        />
        <div className={cn(s.section, s.dark)}>
          <div>
            <Typography className={s.label} type="label1">
              XB3 Earned
            </Typography>
            <Typography type="h2" className={s.numbers}>
              {new BigNumber(bonusCampaignReward).toFixed(2)}
            </Typography>
          </div>
        </div>
        <LabelSection
          icon={<Xb3Logo />}
          title="XB3 Hive"
          background="blue"
          endContent={<LabelSectionLink to="/hive/stake" />}
        />
        <div className={cn(s.section, s.blue)}>
          <div>
            <Typography className={s.label} type="label1">
              Hive Deposits
            </Typography>
            <Typography type="h2" className={s.numbers}>
              ${new BigNumber(totalDeposits).multipliedBy(lpPriceInUsd).toFixed(2)}
            </Typography>
          </div>
          <div>
            <Typography className={s.label} type="label1">
              Pending Rewards (USD)
            </Typography>
            <Typography type="h2" className={s.numbers}>
              ${new BigNumber(hiveRewardEarnedInUsd).toFixed(2)}
            </Typography>
          </div>
        </div>
        <LabelSection
          icon={<ReferralGreenIcon />}
          title="Referrals"
          background="green"
          endContent={<LabelSectionLink to="/referrals" />}
        />
        <div className={cn(s.section, s.green, s.referrals)}>
          <div>
            <Typography className={s.label} type="label1">
              Times Used
            </Typography>
            <Typography type="h2" className={s.numbers}>
              {new BigNumber(referralsStatistic.total.members).toFixed(0)}
            </Typography>
          </div>
          <div>
            <Typography className={s.label} type="label1">
              TVL of Referrals (USD)
            </Typography>
            <Typography type="h2" className={s.numbers}>
              ${new BigNumber(referralsStatistic.total.deposits).toFixed(2)}
            </Typography>
          </div>
          <div>
            <Typography className={s.label} type="label1">
              Pending Rewards
            </Typography>
            <Typography type="h2" className={s.numbers}>
              ${new BigNumber(referralsStatistic.rewards.pending).toFixed(2)}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
