import { FC, ReactElement } from 'react';
import cn from 'clsx';

import { CopyIcon } from '@/assets/img/icons';
import { Button, Typography } from '@/components';
import { copyToClipboard } from '@/utils';

import s from './styles.module.scss';

export interface InfoItemProps {
  title: string;
  subtitle: string;
  icon: ReactElement;
  color?: 'green' | 'blue' | 'purple' | 'orange';
  valueToCopy?: string;
  className?: string;
}

export const InfoItem: FC<InfoItemProps> = ({
  className,
  title,
  subtitle,
  valueToCopy,
  icon,
  color = 'green',
}) => {
  return (
    <div className={cn(s.infoItem, s[color], className)}>
      <div className={s.left}>{icon}</div>
      <div className={s.right}>
        <Typography type="sub2" weight="medium" className={s.title}>
          {title}
        </Typography>
        <Typography type="h4" weight="medium" spacing="0" className={s.subtitle}>
          {subtitle}
        </Typography>
      </div>
      {valueToCopy && (
        <Button onClick={() => copyToClipboard(valueToCopy)} className={s.copyIcon} variant="text">
          <CopyIcon />
        </Button>
      )}
    </div>
  );
};
