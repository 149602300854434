import api from '@/api/axios';

interface SignatureMetadata {
  haveSignature: boolean;
  timestamp: string;
}

export const signaturesApi = {
  getSignatureInfoByAddress: (address: string) =>
    api
      .get<SignatureMetadata>(`/signatures/${address}/`)
      .then((res) => res.data)
      .catch(() => ({ haveSignature: false, timestamp: '' } as SignatureMetadata)),

  addSignedMessage: (data: { address: string; message: string; signature: string }) =>
    api.post('/signatures/add/', {
      ...data,
    }),
};
