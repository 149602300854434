import BigNumber from 'bignumber.js';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { ContractsNames } from '@/config';
import { SECONDS_IN_WEEK } from '@/config/constants';
import apiActions from '@/store/api/actions';
import userActionTypes from '@/store/user/actionTypes';
import { getUserBoostLevelSaga } from '@/store/user/sagas/getUserBoostLevel';
import userSelector from '@/store/user/selectors';
import { VeXb3TokenAbi } from '@/types';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { increaseUnlockTime } from '../actions';
import actionTypes from '../actionTypes';
import { notify } from '@/utils';

export function* increaseUnlockTimeSaga({
  type,
  payload: { web3Provider, weeks },
}: ReturnType<typeof increaseUnlockTime>) {
  yield* put(apiActions.request(type));

  const [veXb3TokenAbi, veXb3TokenAddress] = getContractDataByHisName(ContractsNames.veXb3Token);
  const veXbfContract: VeXb3TokenAbi = yield new web3Provider.eth.Contract(
    veXb3TokenAbi,
    veXb3TokenAddress,
  );

  try {
    const userAddress = yield* select(userSelector.getProp('address'));
    const unlockTimeTimestamp = yield* call(veXbfContract.methods.lockedEnd(userAddress).call);

    const newUnlockTime = new BigNumber(weeks + 1)
      .multipliedBy(SECONDS_IN_WEEK)
      .minus(1)
      .plus(unlockTimeTimestamp)
      .toString();

    // CHECK IF CAN SEND
    yield* call(veXbfContract.methods.increaseUnlockTime(newUnlockTime).estimateGas, {
      from: userAddress,
    });

    yield* call(veXbfContract.methods.increaseUnlockTime(newUnlockTime).send, {
      from: userAddress,
    });

    yield call(getUserBoostLevelSaga, {
      type: userActionTypes.GET_USER_BOOST_LEVEL,
      payload: {
        web3Provider,
        address: userAddress,
      },
    });

    yield* put(apiActions.success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    notify.error('Something may go wrong. Please check your inputs.');
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.INCREASE_UNLOCK_TIME, increaseUnlockTimeSaga);
}
