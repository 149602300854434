import { call, put, select, takeLatest } from 'typed-redux-saga';

import { referralsApi } from '@/api/referralsApi';
import { ContractsNames } from '@/config';
import { DEFAULT_REFERRAL_CODE } from '@/config/constants';
import apiActions from '@/store/api/actions';
import userSelector from '@/store/user/selectors';
import { ReferralProgramAbi } from '@/types/contracts/ReferralProgramAbi';
import { notify } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { register } from '../actions';
import actionTypes from '../actionTypes';
import { updateReferralsState } from '../reducer';

export function* registerSaga({
  type,
  payload: { web3Provider, referrerCode },
}: ReturnType<typeof register>) {
  yield* put(apiActions.request(type));

  try {
    const [abi, address] = getContractDataByHisName(ContractsNames.referralProgram);
    const referralProgrammContract: ReferralProgramAbi = yield new web3Provider.eth.Contract(
      abi,
      address,
    );
    const userAddress = yield* select(userSelector.getProp('address'));
    let referrerAddress = '';

    try {
      const referrerInfo = yield* call(referralsApi.getReferrerFromCode, referrerCode);
      referrerAddress = referrerInfo.data.address;
    } catch (error) {
      if (referrerCode !== DEFAULT_REFERRAL_CODE) {
        notify.error('Referral code not found!');
        yield* put(apiActions.error(type));
        return;
      }

      referrerAddress = yield* call(referralProgrammContract.methods.rootAddress().call);
    }

    // CHECK IF CAN SEND
    yield* call(
      referralProgrammContract.methods['registerUser(address)'](referrerAddress).estimateGas,
      {
        from: userAddress,
      },
    );

    yield* call(referralProgrammContract.methods['registerUser(address)'](referrerAddress).send, {
      from: userAddress,
    });

    yield* put(updateReferralsState({ isUserRegistered: true }));

    yield* put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    notify.error('Something may go wrong. Please check your inputs.');
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.REGISTER, registerSaga);
}
