import { BigNumber } from 'bignumber.js';
import { call, put, takeLatest } from 'typed-redux-saga';

import { ContractsNames } from '@/config';
import { SECONDS_IN_YEAR } from '@/config/constants';
import apiActions from '@/store/api/actions';
import { updateLockupState } from '@/store/lockup/reducer';
import { VeXb3TokenAbi, VotingStakingRewardsAbi } from '@/types';
import { createContract, fromDecimals } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { getLockupData } from '../actions';
import actionTypes from '../actionTypes';

export function* getLockupDataSaga({ type }: ReturnType<typeof getLockupData>) {
  yield* put(apiActions.request(type));

  const [veXb3TokenAbi, veXb3TokenAddress] = getContractDataByHisName(ContractsNames.veXb3Token);
  const [votingStakingRewardsAbi, votingStakingRewardsAddress] = getContractDataByHisName(
    ContractsNames.votingStakingRewards,
  );

  try {
    const veXbfContract: VeXb3TokenAbi = yield createContract(veXb3TokenAbi, veXb3TokenAddress);

    const totalLocked = yield* call(veXbfContract.methods.supply().call);

    const votingStakingRewardsContract: VotingStakingRewardsAbi = yield createContract(
      votingStakingRewardsAbi,
      votingStakingRewardsAddress,
    );
    const totalSupply = yield* call(votingStakingRewardsContract.methods.totalSupply().call);
    const rewardRate = yield* call(votingStakingRewardsContract.methods.rewardRate().call);

    const apr = new BigNumber(rewardRate)
      .multipliedBy(SECONDS_IN_YEAR)
      .dividedBy(fromDecimals(totalSupply))
      .toString();

    yield* put(
      updateLockupState({
        totalLocked: fromDecimals(totalLocked),
        lockedApr: Number.isNaN(+apr) ? '0' : apr,
      }),
    );

    yield* put(apiActions.success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_LOCKUP_DATA, getLockupDataSaga);
}
