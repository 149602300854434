import { VFC } from 'react';
import cn from 'clsx';

import { Typography } from '@/components';

import s from './styles.module.scss';

export interface ProgressBarProps {
  title: string;
  value: number;
  className?: string;
}

export const ProgressBar: VFC<ProgressBarProps> = ({ title, value, className }) => {
  return (
    <div className={cn(s.progress_wrapper, className)}>
      <Typography className={s.title} type="body2">
        {title}
      </Typography>
      <div className={s.progress_container}>
        <div className={s.progress}>
          <div className={s.track} style={{ width: `${value}%` }} />
          <span className={s.handle} style={{ left: `calc(${value}% - 35px)` }}>
            {value}%
          </span>
        </div>
      </div>
    </div>
  );
};
