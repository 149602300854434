import { FC, useMemo } from 'react';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import cn from 'clsx';

import { Subheader, Typography } from '@/components';
import { useShallowSelector } from '@/hooks';
import { AdditionalInformation, Boost, Lockup } from '@/pages/Xb3/components';
import ratesSelector from '@/store/rates/selectors';
import userSelector from '@/store/user/selectors';

import { Rewards } from './components/Rewards';
import { links } from './Xb3.helper';

import s from './Xb3.module.scss';

const Xb3: FC = () => {
  const location = useParams();

  const slug = useMemo(() => location['*'], [location]);

  const userXb3Balance = useShallowSelector(userSelector.getProp('xb3Balance'));
  const { price: xb3PriceInUsd } = useShallowSelector(ratesSelector.getProp('xb3'));

  return (
    <div className={s.xbf_wrapper}>
      <Subheader tokenPrice={xb3PriceInUsd} userBalance={userXb3Balance} />
      <div className="card">
        <div className="card_header">
          {links.map(({ slug: linkSlug }) => (
            <Link key={linkSlug} className={s.link} to={`/xb3/${linkSlug}`}>
              <Typography
                className={cn(s.linkTitle, s[linkSlug], { [s.linkTitleActive]: linkSlug === slug })}
                type="body1"
                weight="normal"
              >
                {linkSlug}
              </Typography>
            </Link>
          ))}
        </div>
        <div className={s.body}>
          <Routes>
            <Route path="lockup" element={<Lockup />} />
            <Route path="rewards" element={<Rewards />} />
            <Route path="boost" element={<Boost />} />
          </Routes>
        </div>
      </div>
      <AdditionalInformation />
    </div>
  );
};
export default Xb3;
