import { FC, useState } from 'react';
import BigNumber from 'bignumber.js';
import cn from 'clsx';

import { CommunityIcon } from '@/assets/img/icons';
import { Expander, Typography } from '@/components';
import { XB3_DOCS_URL } from '@/config/constants';
import { Referral } from '@/types';
import { formatDate } from '@/utils';

import s from './styles.module.scss';

export interface ReferralNetworkProps {
  className?: string;
  referrals: Referral[];
  sortReferrals: (value: string, referrals: Array<Referral>) => void;
}

export const ReferralNetwork: FC<ReferralNetworkProps> = ({
  className,
  referrals,
  sortReferrals,
}) => {
  const [sortedByLiquidityAsc, setSortedByLiquidityAsc] = useState(false);
  const [sortedByTimeAsc, setSortedByTimeAsc] = useState(false);

  const beforeSort = (value: string) => {
    let sortType = '';
    if (value === 'byLiquidity' && sortedByLiquidityAsc) {
      sortType = 'byLiquidityDesc';
      setSortedByLiquidityAsc(false);
    }
    if (value === 'byLiquidity' && !sortedByLiquidityAsc) {
      sortType = 'byLiquidityAsc';
      setSortedByLiquidityAsc(true);
    }
    if (value === 'byRegTime' && sortedByTimeAsc) {
      sortType = 'byRegTimeDesc';
      setSortedByTimeAsc(false);
    }
    if (value === 'byRegTime' && !sortedByTimeAsc) {
      sortType = 'byRegTimeAsc';
      setSortedByTimeAsc(true);
    }
    sortReferrals(sortType, referrals);
  };

  return (
    <Expander
      header={
        <div className={cn(s.headerContent)}>
          <CommunityIcon className={cn(s.headerIcon)} />
          <div>
            <Typography type="h4" weight="medium" isUpper>
              MY Referral Network
            </Typography>
            <Typography spacing={0} className={s.headerSubtitle} weight="medium" type="label2">
              For more information on the XB3 Finance please visit&nbsp;
              <a target="_blank" rel="noreferrer noopener" href={XB3_DOCS_URL}>
                docs.xb3.finance
              </a>
            </Typography>
          </div>
        </div>
      }
      className={cn(s.referralNetwork, className)}
    >
      <div className={s.tableWrapper}>
        <div className={s.table}>
          <div className={s.tableHeader}>
            <div
              role="button"
              onKeyDown={() => {}}
              onClick={() => beforeSort('byRegTime')}
              tabIndex={0}
              className={s.tableHeaderItem}
            >
              <Typography type="label2" className={s.tableHeaderFirstColumn}>
                REGISTRATION TIME (±)
              </Typography>
            </div>
            <Typography type="label2">MEMBER CODE</Typography>
            <div
              role="button"
              onKeyDown={() => {}}
              onClick={() => beforeSort('byLiquidity')}
              tabIndex={0}
              className={s.tableHeaderItem}
            >
              <Typography type="label2">MEMBER LIQUIDITY (±)</Typography>
            </div>
          </div>
          {referrals.map((referral) => (
            <div key={referral.code} className={s.tableRow}>
              <Typography spacing={0} weight="semiBold" type="label2">
                {formatDate(+referral.createdAt)}
              </Typography>
              <Typography spacing={0} weight="semiBold" type="label2">
                {referral.code}
              </Typography>
              <Typography spacing={0} weight="semiBold" type="label2">
                ${new BigNumber(referral.deposits).toFixed(4)} (±USDC)
              </Typography>
            </div>
          ))}
          {referrals.length < 1 && (
            <div className={s.tableRow}>
              <Typography spacing={0} weight="semiBold" type="label2">
                -
              </Typography>
              <Typography spacing={0} weight="semiBold" type="label2">
                -
              </Typography>
              <Typography spacing={0} weight="semiBold" type="label2">
                -
              </Typography>
            </div>
          )}
        </div>
      </div>
      <div className={s.subinfo}>
        <Typography className={s.subinfoText} type="sub2">
          <span>Note</span>: Only your TIER 1 Network members can be displayed below. Liquidity is
          estimated in USDC.
        </Typography>
      </div>
    </Expander>
  );
};
