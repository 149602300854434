import { CSSProperties, ReactElement, VFC } from 'react';
import cn from 'clsx';

import labelBgBlue from '@/assets/img/label-bg-blue.jpg';
import labelBgDark from '@/assets/img/label-bg-dark.jpg';
import labelBgGreen from '@/assets/img/label-bg-green.jpg';
import labelBgPink from '@/assets/img/label-bg-pink.jpg';

import { Typography } from '@/components';

import s from './styles.module.scss';

type Backgrounds = 'dark' | 'blue' | 'pink' | 'green';

export interface LabelSectionProps {
  title: string;
  icon: ReactElement;
  endContent?: ReactElement;
  background?: Backgrounds;
  className?: string;
  titleClassName?: string;
}

const bgStyle: CSSProperties = {
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const backgrounds = {
  dark: labelBgDark,
  pink: labelBgPink,
  green: labelBgGreen,
  blue: labelBgBlue,
};

/**
 * @param {'pink' | 'blue' | 'green' | 'dark'} [background = 'blue'] - background image color
 * * dark - dark bg
 * * blue - blue bg
 * * pink - pink bg
 * * green - green bg
 * @param {string} [title] - title
 * @param {string} [titleClassName] - title title className
 * @param {ReactElement} [icon] - icon before title
 * @param {ReactElement} [endContent] - JSX element for the end of the card
 */
export const LabelSection: VFC<LabelSectionProps> = ({
  className,
  background = 'blue',
  title = 'Title',
  icon,
  endContent,
  titleClassName,
}) => {
  return (
    <section
      style={{ background: `url(${backgrounds[background]})`, ...bgStyle }}
      className={cn(s.labelSection, className)}
    >
      <div className={cn(s.left)}>
        <div className={s.icon}>{icon}</div>
        <Typography className={cn(s.title, titleClassName)} type="h4" spacing={0.1}>
          {title}
        </Typography>
      </div>
      {endContent}
    </section>
  );
};
