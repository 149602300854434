import { XbCvxCrvLogos } from '@/assets/img/icons';

export const filterItems = {
  defaultOption: {
    text: 'Sort By',
    value: '',
  },
  othersOptions: [
    {
      text: 'Average APR',
      value: 'apr',
    },
    {
      text: 'My deposits',
      value: 'myDeposits',
    },
    {
      text: 'Total Value Locked',
      value: 'tvl',
    },
  ],
};

export const stakesInfo = [
  // {
  //   contracts: [
  //     {
  //       title: 'LP token Address',
  //       value: '0xd632f22692FaC7611d2AA1C0D552930D43CAEd3B',
  //       valueToCopy: '0xd632f22692FaC7611d2AA1C0D552930D43CAEd3B',
  //     },
  //     {
  //       title: 'Deposit contract address',
  //       value: '0x2f71BB915bdc84dE7a168d8Ef4b7a6496fDd5e76',
  //       valueToCopy: '0x2f71BB915bdc84dE7a168d8Ef4b7a6496fDd5e76',
  //     },
  //     {
  //       title: 'Rewards contract address (CRV)',
  //       value: '0x2f71BB915bdc84dE7a168d8Ef4b7a6496fDd5e76',
  //       valueToCopy: '0x2f71BB915bdc84dE7a168d8Ef4b7a6496fDd5e76',
  //     },
  //     {
  //       title: 'Rewards contract address (CVX)',
  //       value: '0x2f71BB915bdc84dE7a168d8Ef4b7a6496fDd5e76',
  //       valueToCopy: '0x2f71BB915bdc84dE7a168d8Ef4b7a6496fDd5e76',
  //     },
  //     {
  //       title: 'Rewards contract address (XBF)',
  //       value: '0x2f71BB915bdc84dE7a168d8Ef4b7a6496fDd5e76',
  //       valueToCopy: '0x2f71BB915bdc84dE7a168d8Ef4b7a6496fDd5e76',
  //     },
  //   ],
  //   cardLogo: <CvxCrvLogos />,
  //   cardTitle: 'cvxCRV Vault',
  //   apr: 23,
  //   myDeposits: 123,
  //   tvl: 12,
  //   popoverContent:
  //     'Deposit liquidity into the Curve cvxCRV pool (without staking in the Curve gauge), and stake your cvxCRV LP tokens here to earn CRV, CVX, XB3 and veXB3.',
  //   depositSeparateTokens: true,
  // },
  {
    // contracts: [
    //   {
    //     title: 'LP token Address',
    //     value: '0xd632f22692FaC7611d2AA1C0D552930D43CAEd3B',
    //     valueToCopy: '0xd632f22692FaC7611d2AA1C0D552930D43CAEd3B',
    //   },
    //   {
    //     title: 'Deposit contract address',
    //     value: '0x2f71BB915bdc84dE7a168d8Ef4b7a6496fDd5e76',
    //     valueToCopy: '0x2f71BB915bdc84dE7a168d8Ef4b7a6496fDd5e76',
    //   },
    //   {
    //     title: 'Rewards contract address (CRV)',
    //     value: '0x2f71BB915bdc84dE7a168d8Ef4b7a6496fDd5e76',
    //     valueToCopy: '0x2f71BB915bdc84dE7a168d8Ef4b7a6496fDd5e76',
    //   },
    //   {
    //     title: 'Rewards contract address (CVX)',
    //     value: '0x2f71BB915bdc84dE7a168d8Ef4b7a6496fDd5e76',
    //     valueToCopy: '0x2f71BB915bdc84dE7a168d8Ef4b7a6496fDd5e76',
    //   },
    //   {
    //     title: 'Rewards contract address (XBF)',
    //     value: '0x2f71BB915bdc84dE7a168d8Ef4b7a6496fDd5e76',
    //     valueToCopy: '0x2f71BB915bdc84dE7a168d8Ef4b7a6496fDd5e76',
    //   },
    // ],
    cardLogo: <XbCvxCrvLogos />,
    myDeposits: 245.24,
    popoverContent:
      'Deposit liquidity into the Curve cvxCRV Vault, and stake your cvxCRV LP tokens here to earn CRV, CVX, XB3 and veXB3.',
  },
];
