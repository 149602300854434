import { call, put, takeLatest } from 'typed-redux-saga';

import ratesApi from '@/api/ratesApi';
import apiActions from '@/store/api/actions';
import { updateRatesState } from '@/store/rates/reducer';

import { getRates } from '../actions';
import actionTypes from '../actionTypes';

export function* getRatesSaga({ type }: ReturnType<typeof getRates>) {
  yield* put(apiActions.request(type));

  try {
    const { data } = yield* call(ratesApi.getTokensRates);
    const xb3Rate = data.find((rate) => rate.symbol === 'XB3');
    const ethRate = data.find((rate) => rate.symbol === 'ETH');
    const crvRate = data.find((rate) => rate.symbol === 'CRV');
    const cvxRate = data.find((rate) => rate.symbol === 'CVX');
    const cvxCrvRate = data.find((rate) => rate.symbol === 'cvxCRV');
    const crvCvxCrvLpRate = data.find((rate) => rate.symbol === 'CRV/cvxCRV LP');

    yield* put(
      updateRatesState({
        xb3: xb3Rate,
        eth: ethRate,
        crv: crvRate,
        cvx: cvxRate,
        cvxCrv: cvxCrvRate,
        crvCvxCrvLp: crvCvxCrvLpRate,
      }),
    );

    yield* put(apiActions.success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_RATES, getRatesSaga);
}
