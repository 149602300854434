import lockActionTypes from '@/store/lockup/actionTypes';
import userActionTypes from '@/store/user/actionTypes';
import { UIState } from '@/types';
import { RequestStatus } from '@/types/store';

import { getUIReducer } from '.';

const initialState: UIState = {
  [userActionTypes.GET_TOKEN_BALANCE]: RequestStatus.INIT,
  [lockActionTypes.LOCK]: RequestStatus.INIT,
  [lockActionTypes.UNLOCK]: RequestStatus.INIT,
  [lockActionTypes.INCREASE_LOCKUP_AMOUNT]: RequestStatus.INIT,
  [lockActionTypes.INCREASE_UNLOCK_TIME]: RequestStatus.INIT,
};

const uiReducer = getUIReducer(initialState);

export default uiReducer;
