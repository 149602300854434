import { all, put, takeLatest } from 'typed-redux-saga';

import { ContractsNames } from '@/config';
import apiActions from '@/store/api/actions';
import { updateUserState } from '@/store/user/reducer';
import { VeXb3TokenAbi, VotingStakingRewardsAbi } from '@/types';
import { fromDecimals } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { getUserLockupData } from '../actions';
import actionTypes from '../actionTypes';

export function* getUserLockupDataSaga({
  type,
  payload: { web3Provider, address },
}: ReturnType<typeof getUserLockupData>) {
  yield* put(apiActions.request(type));

  const [veXb3TokenAbi, veXb3TokenAddress] = getContractDataByHisName(ContractsNames.veXb3Token);
  const [votingStakingRewardsAbi, votingStakingRewardsAddress] = getContractDataByHisName(
    ContractsNames.votingStakingRewards,
  );

  try {
    const veXbfContract: VeXb3TokenAbi = yield new web3Provider.eth.Contract(
      veXb3TokenAbi,
      veXb3TokenAddress,
    );
    const votingStakingRewardsContract: VotingStakingRewardsAbi =
      yield new web3Provider.eth.Contract(votingStakingRewardsAbi, votingStakingRewardsAddress);

    const lockedAssetsRequest = veXbfContract.methods.locked(address).call();
    const lockStarsRequest = veXbfContract.methods.lockStarts(address).call();
    const lockEndsRequest = veXbfContract.methods.lockedEnd(address).call();
    const currentLockAmountRequest = veXbfContract.methods.lockedAmount(address).call();
    const pendingRewardRequest = votingStakingRewardsContract.methods.earned(address).call();

    const [lockedAssets, lockStars, lockEnds, currentLockAmount, pendingReward]: any[] = yield* all(
      [
        lockedAssetsRequest,
        lockStarsRequest,
        lockEndsRequest,
        currentLockAmountRequest,
        pendingRewardRequest,
      ],
    );

    yield put(
      updateUserState({
        lockup: {
          lockedBalance: fromDecimals(lockedAssets.amount),
          lockEnds,
          lockStars,
          currentLockAmount,
          pendingReward: fromDecimals(pendingReward),
        },
      }),
    );

    yield* put(apiActions.success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_USER_LOCKUP_DATA, getUserLockupDataSaga);
}
