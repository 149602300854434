import { FC, PropsWithChildren, useRef } from 'react';
import cn from 'clsx';

import { QuestionIcon } from '@/assets/img/icons';

import s from './styles.module.scss';

export interface InfoPopoverProps {
  align?: 'left' | 'right' | 'center';
  maxWidth?: number;
  className?: string;
  contentClassName?: string;
  iconProps?: React.SVGProps<SVGSVGElement>;
}

/**
 * @param {'left' | 'right' | 'center'} [align = 'center'] - content align
 * * center
 * * left
 * * right
 * @param {maxWidth} [maxWidth = '335'] - maxWidth of content
 * @param {string} [className] - the wrapper class name
 * @param {string} [contentClassName] - the content class name
 */
export const InfoPopover: FC<PropsWithChildren<InfoPopoverProps>> = ({
  className,
  children,
  contentClassName,
  align = 'center',
  maxWidth = 'clamp(100px, 80vw, 355px)',
  iconProps,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <div className={cn(s.infoPopover, className)}>
      <QuestionIcon {...iconProps} width="24px" height="24px" />
      <div
        ref={contentRef}
        style={{ maxWidth }}
        className={cn(s.infoPopoverContent, s[align], contentClassName)}
      >
        {children}
      </div>
    </div>
  );
};
