import { useMemo, VFC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'clsx';

import { ExternalLinkIcon, MainLogo } from '@/assets/img/icons';
import { Button } from '@/components';
import { useShallowSelector } from '@/hooks';
import { useWindowState } from '@/hooks/useWindowState';
import { routes } from '@/router';
import { useWalletConnectorContext } from '@/services';
import userSelector from '@/store/user/selectors';
import { Chains, WalletProviders } from '@/types';
import { linkToEtherscan } from '@/utils/linkToFantomScan';

import s from './Header.module.scss';

const HeaderMenu: VFC = () => {
  const { pathname } = useLocation();
  return (
    <div className={s.menu_wrapper}>
      {routes.map((route) => {
        return (
          route.isMenu && (
            <Link
              key={route.name}
              to={route.defaultPath || route.path}
              className={cn(
                s.menu_link,
                [route.defaultPath, route.path].includes(pathname) && s.active,
              )}
            >
              {route.name}
            </Link>
          )
        );
      })}
    </div>
  );
};

const Header: VFC = () => {
  const { width } = useWindowState();
  const { connect } = useWalletConnectorContext();
  const address = useShallowSelector(userSelector.getProp('address'));
  const isMobile = useMemo(() => window.innerWidth < 920 && width < 920, [width]);

  return (
    <div className={s.header_wrapper}>
      <div className={s.header_inner}>
        <div className={s.logo}>
          <MainLogo />
        </div>
        {!isMobile && <HeaderMenu />}
        {address ? (
          <a
            href={linkToEtherscan.address(address)}
            target="_blank"
            rel="noreferrer noopener"
            className={s.userAddress}
          >
            <div className={s.address}>{address}</div>
            <ExternalLinkIcon />
          </a>
        ) : (
          <Button
            className={s.button}
            onClick={() => connect(WalletProviders.metamask, Chains.Ethereum)}
          >
            Connect
          </Button>
        )}
      </div>
      {isMobile && <HeaderMenu />}
    </div>
  );
};

export default Header;
