import { FC } from 'react';

import { MegaphoneIcon, Xb3Icon } from '@/assets/img/icons';
import { Typography } from '@/components';

import s from './Custody.module.scss';

const Custody: FC = () => {
  return (
    <section className={s.custody_wrapper}>
      <div className={s.coming_soon_wrapper}>
        <MegaphoneIcon />
        <div className={s.text_wrapper}>
          <Typography type="h5" weight="semiBold" className={s.title}>
            Institutional grade custody solution
          </Typography>
          <div className={s.coming_soon}>Coming Soon!</div>
        </div>
        <Xb3Icon className={s.xb3_logo} />
      </div>
    </section>
  );
};

export default Custody;
