import BigNumber from 'bignumber.js';
import { all, call, put, takeLatest } from 'typed-redux-saga';

import { ContractsNames } from '@/config';
import { error, request, success } from '@/store/api/actions';
import { updateUserState } from '@/store/user/reducer';
import { ConvexVaultAbi, VeXb3TokenAbi, VotingStakingRewardsAbi } from '@/types';
import { fromDecimals } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { getUserHiveRewards } from '../actions';
import actionTypes from '../actionTypes';

export function* getUserHiveRewardsSaga({
  type,
  payload: { web3Provider, address },
}: ReturnType<typeof getUserHiveRewards>) {
  yield put(request(type));

  const [vaultAbi, vaultAddress] = getContractDataByHisName(ContractsNames.convexVault);
  const [veXb3TokenAbi, veXb3TokenAddress] = getContractDataByHisName(ContractsNames.veXb3Token);
  const [votingStakingRewardsAbi, votingStakingRewardsAddress] = getContractDataByHisName(
    ContractsNames.votingStakingRewards,
  );

  try {
    const vaultContract: ConvexVaultAbi = yield new web3Provider.eth.Contract(
      vaultAbi,
      vaultAddress,
    );
    const veXb3TokenContract: VeXb3TokenAbi = yield new web3Provider.eth.Contract(
      veXb3TokenAbi,
      veXb3TokenAddress,
    );
    const votingStakingRewardsContract: VotingStakingRewardsAbi =
      yield new web3Provider.eth.Contract(votingStakingRewardsAbi, votingStakingRewardsAddress);

    const xb3EarnedRequest = vaultContract.methods.earned(address, 0).call();
    const crvEarnedRequest = vaultContract.methods.earned(address, 1).call();
    const cvxEarnedRequest = vaultContract.methods.earned(address, 2).call();

    const pointHistory = yield* call(veXb3TokenContract.methods.pointHistory(0).call);
    const maxTime = yield* call(veXb3TokenContract.methods.MAXTIME().call);
    const userLockedBalance = yield* call(
      votingStakingRewardsContract.methods.balanceOf(address).call,
    );

    const isUserHasLock = new BigNumber(userLockedBalance).isGreaterThan(0);
    const programStartTimestamp = pointHistory.ts;

    const programEndTimestamp = +programStartTimestamp + +maxTime;
    const currentTime = Math.floor(Date.now() / 1000);
    const untilProgramEnd = programEndTimestamp - currentTime;
    const isProgramEnd = untilProgramEnd < 0;

    const percentageToBeLocked = isProgramEnd
      ? yield* call(vaultContract.methods.percentageToBeLocked().call)
      : '0';
    const percentageToUnlocked = 100 - +percentageToBeLocked;

    const [xb3Earned, crvEarned, cvxEarned]: any[] = yield* all([
      xb3EarnedRequest,
      crvEarnedRequest,
      cvxEarnedRequest,
    ]);

    const xb3BalanceBeforeProgramEnd = new BigNumber(xb3Earned)
      .multipliedBy(percentageToUnlocked)
      .dividedBy(100)
      .toString();

    const veXb3BalanceBeforeProgramEnd = new BigNumber(xb3Earned)
      .multipliedBy(untilProgramEnd)
      .dividedBy(maxTime)
      .toString();

    yield put(
      updateUserState({
        hiveRewards: {
          xb3:
            isUserHasLock || !isProgramEnd
              ? fromDecimals(xb3BalanceBeforeProgramEnd)
              : fromDecimals(xb3Earned),
          crv: fromDecimals(crvEarned),
          cvx: fromDecimals(cvxEarned),
          veXb3: isProgramEnd ? '0' : fromDecimals(veXb3BalanceBeforeProgramEnd),
        },
      }),
    );

    yield put(success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_USER_HIVE_REWARDS, getUserHiveRewardsSaga);
}
