import { call, put, takeLatest } from 'typed-redux-saga';

import { ContractsNames } from '@/config';
import { error, request, success } from '@/store/api/actions';
import { LpTokenAbi } from '@/types';
import { fromDecimals } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { getCrvTokenBalance } from '../actions';
import actionTypes from '../actionTypes';
import { updateUserState } from '../reducer';

export function* getCrvTokenBalanceSaga({
  type,
  payload: { web3Provider, address },
}: ReturnType<typeof getCrvTokenBalance>) {
  yield put(request(type));

  const [crvTokenAbi, crvTokenAddress] = getContractDataByHisName(ContractsNames.crvToken);

  try {
    const lpTokenContract: LpTokenAbi = yield new web3Provider.eth.Contract(
      crvTokenAbi,
      crvTokenAddress,
    );
    const balance = yield* call(lpTokenContract.methods.balanceOf(address).call);
    const decimals = yield* call(lpTokenContract.methods.decimals().call);

    yield put(updateUserState({ crvTokenBalance: fromDecimals(balance, +decimals) }));

    yield put(success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_CRV_TOKEN_BALANCE, getCrvTokenBalanceSaga);
}
