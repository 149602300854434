import { put, takeLatest } from 'typed-redux-saga';

import apiActions from '@/store/api/actions';

import { claim } from '../actions';
import actionTypes from '../actionTypes';

export function* claimSaga({ type, payload: { web3Provider } }: ReturnType<typeof claim>) {
  yield* put(apiActions.request(type));

  try {
    // do logic here
    console.log(web3Provider);

    yield* put(apiActions.success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CLAIM, claimSaga);
}
