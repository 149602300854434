import { FC } from 'react';
import BigNumber from 'bignumber.js';
import cn from 'clsx';

import {
  NetworkDepositsIcon,
  NetworkMembersIcon,
  TierOneDepositIcon,
  TierOneIcon,
  TierThreeDepositsIcon,
  TierThreeMembersIcon,
  TierTwoDepositsIcon,
  TierTwoMembersIcon,
} from '@/assets/img/icons';
import { Typography } from '@/components';
import { ReferralsStatistic } from '@/types';

import s from './styles.module.scss';
import { convertBigNumbersToReadable } from '@/utils';

export interface StatisticProps {
  statistic: ReferralsStatistic;
  className?: string;
}

export const Statistic: FC<StatisticProps> = ({ className, statistic }) => {
  return (
    <div className={cn(s.section, className)}>
      <Typography className={s.title} type="body1" isUpper>
        Referral stats
      </Typography>
      <div className={s.statistic}>
        <div className={s.item}>
          <div className={s.itemIcon}>
            <NetworkMembersIcon />
          </div>
          <div className={s.right}>
            <Typography weight="medium" type="h2" className={s.itemTitle}>
              {statistic.total.members}
            </Typography>
            <Typography type="label1" className={s.itemSubtitle}>
              My Network Members
            </Typography>
          </div>
        </div>
        <div className={s.item}>
          <div className={s.itemIcon}>
            <NetworkDepositsIcon />
          </div>
          <div className={s.right}>
            <Typography weight="medium" type="h2" className={s.itemTitle}>
              ${convertBigNumbersToReadable(statistic.total.deposits)}
            </Typography>
            <Typography type="label1" className={s.itemSubtitle}>
              My Network Deposits
            </Typography>
          </div>
        </div>
        <div className={s.item}>
          <div className={s.itemIcon}>
            <TierOneIcon />
          </div>
          <div className={s.right}>
            <Typography weight="medium" type="h2" className={s.itemTitle}>
              {statistic.tiers[1].members}
            </Typography>
            <Typography type="label1" className={s.itemSubtitle}>
              Tier 1 Members
            </Typography>
          </div>
        </div>
        <div className={s.item}>
          <div className={s.itemIcon}>
            <TierOneDepositIcon />
          </div>
          <div className={s.right}>
            <Typography weight="medium" type="h2" className={s.itemTitle}>
              ${convertBigNumbersToReadable(statistic.tiers[1].deposits)}
            </Typography>
            <Typography type="label1" className={s.itemSubtitle}>
              Tier 1 Deposits
            </Typography>
          </div>
        </div>
        <div className={s.item}>
          <div className={s.itemIcon}>
            <TierTwoMembersIcon />
          </div>
          <div className={s.right}>
            <Typography weight="medium" type="h2" className={s.itemTitle}>
              {statistic.tiers[2].members}
            </Typography>
            <Typography type="label1" className={s.itemSubtitle}>
              Tier 2 Members
            </Typography>
          </div>
        </div>
        <div className={s.item}>
          <div className={s.itemIcon}>
            <TierTwoDepositsIcon />
          </div>
          <div className={s.right}>
            <Typography weight="medium" type="h2" className={s.itemTitle}>
              ${convertBigNumbersToReadable(statistic.tiers[2].deposits)}
            </Typography>
            <Typography type="label1" className={s.itemSubtitle}>
              Tier 2 Deposits
            </Typography>
          </div>
        </div>
        <div className={s.item}>
          <div className={s.itemIcon}>
            <TierThreeMembersIcon />
          </div>
          <div className={s.right}>
            <Typography weight="medium" type="h2" className={s.itemTitle}>
              {statistic.tiers[3].members}
            </Typography>
            <Typography type="label1" className={s.itemSubtitle}>
              Tier 3 Members
            </Typography>
          </div>
        </div>
        <div className={s.item}>
          <div className={s.itemIcon}>
            <TierThreeDepositsIcon />
          </div>
          <div className={s.right}>
            <Typography weight="medium" type="h2" className={s.itemTitle}>
              ${convertBigNumbersToReadable(statistic.tiers[3].deposits)}
            </Typography>
            <Typography type="label1" className={s.itemSubtitle}>
              Tier 3 Deposits
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
