export const isMainnet = true;

export const DAYS_IN_YEAR = 365;
export const DAYS_IN_WEEK = 7;
export const SECONDS_IN_DAY = isMainnet ? 86400 : 514.5;
export const SECONDS_IN_WEEK = isMainnet ? 604800 : 3600;
export const SECONDS_IN_YEAR = 31_536_000;
export const LIQUIDITY_DEADLINE_IN_SECONDS = 1200;
export const SLIPPAGE = 0.5;
export const DEFAULT_REFERRAL_CODE = 'XB3-DEFAULT';
export const BOOST_MAX_LEVEL = 2.5;
export const mainnetRpc = 'https://mainnet.infura.io/v3/0f2e636e0fb7426786352dc8d8acba9f';
export const XB3_DOCS_URL = 'https://docs.xb3.finance/';
export const MAX_UINT =
  '115792089237316195423570985008687907853269984665640564039457584007913129639935';
