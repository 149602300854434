import { all, put, select, takeLatest } from 'typed-redux-saga';

import { ContractsNames } from '@/config';
import { error, request, success } from '@/store/api/actions';
import hiveSelector from '@/store/hive/selectors';
import { fromDecimals } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { getUserVaultsData } from '../actions';
import actionTypes from '../actionTypes';
import { updateUserState } from '../reducer';

export function* getUserVaultsDataSaga({
  type,
  payload: { web3Provider, userAddress },
}: ReturnType<typeof getUserVaultsData>) {
  yield put(request(type));

  const [vaultAbi] = getContractDataByHisName(ContractsNames.convexVault);

  try {
    const vaults = yield* select(hiveSelector.getProp('vaults'));
    const contractsPromises: any = vaults.map(
      ({ address }) => new web3Provider.eth.Contract(vaultAbi, address),
    );
    const contracts: any = yield* all(contractsPromises); // TODO change any
    const userDepositsPromise = vaults.map((_, index) =>
      contracts[index].methods.balanceOf(userAddress).call(),
    );
    const userDeposits: any = yield* all(userDepositsPromise); // TODO change any

    yield put(
      updateUserState({
        hiveDeposits: userDeposits.map((deposit: string) => fromDecimals(deposit)),
      }),
    );

    yield put(success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_USER_VAULTS_DATA, getUserVaultsDataSaga);
}
