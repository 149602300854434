import { call, put, select, takeLatest } from 'typed-redux-saga';

import { ContractsNames } from '@/config';
import { error, request, success } from '@/store/api/actions';
import userSelector from '@/store/user/selectors';
import { Xbe2Xb3MigratorAbi } from '@/types';
import { fromDecimals, notify } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { claimMigrationTokens } from '../actions';
import actionTypes from '../actionTypes';
import { setMigrationSuccess } from '../reducer';

export function* claimMigrationTokensSaga({
  type,
  payload: { web3Provider },
}: ReturnType<typeof claimMigrationTokens>) {
  yield put(request(type));

  const [abi, address] = getContractDataByHisName(ContractsNames.xbe2xb3Migrator);
  const { address: userAddress, migrationInfo } = yield* select(userSelector.getUser);

  try {
    const migratorContract: Xbe2Xb3MigratorAbi = yield new web3Provider.eth.Contract(abi, address);

    const migrationParams = [
      migrationInfo.address,
      migrationInfo.xbeBalance,
      migrationInfo.vsrStaked,
      migrationInfo.vsrReward,
      migrationInfo.bcStaked,
      migrationInfo.bcReward,
      migrationInfo.referralReward,
      migrationInfo.vexbeLockedAmount,
      migrationInfo.vexbeLockedEnd,
      migrationInfo.sushiVaultEarned,
      migrationInfo.frauxVaultEarned,
      migrationInfo.crvCvxVaultEarned,
    ];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore smart contract first argument wrong type
    yield* call(migratorContract.methods.migrate(migrationParams, migrationInfo.merkleProof).send, {
      from: userAddress,
    });

    notify.success(`You have successfully claimed ${fromDecimals(migrationInfo.xbeBalance)} XB3`);
    yield* put(setMigrationSuccess());

    yield put(success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CLAIM_MIGRATION_TOKENS, claimMigrationTokensSaga);
}
