import { ClaimXb3, Custody, Dashboard, Hive, Referrals, Xb3 } from '@/pages';

export const routes = [
  {
    name: 'Dashboard',
    path: '/',
    component: <Dashboard />,
    isMenu: true,
  },
  {
    name: 'XB3 & veXB3',
    path: '/xb3/*',
    defaultPath: '/xb3/lockup',
    component: <Xb3 />,
    isMenu: true,
  },
  {
    name: 'Hive',
    path: '/hive/*',
    defaultPath: '/hive/stake',
    component: <Hive />,
    isMenu: true,
  },
  {
    name: 'Referrals',
    path: '/referrals',
    component: <Referrals />,
    isMenu: true,
  },
  {
    name: 'Custody',
    path: '/custody',
    component: <Custody />,
    isMenu: true,
  },
  {
    name: 'Claim XB3',
    path: '/claim-xb3',
    component: <ClaimXb3 />,
    isMenu: true,
  },
];
