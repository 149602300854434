import { FC } from 'react';
import BigNumber from 'bignumber.js';

import {
  BookIcon,
  MountainIcon,
  PoweredByLogo,
  WalletGreenIcon,
  WalletPinkIcon,
} from '@/assets/img/icons';
import { Button, Typography } from '@/components';
import { XB3_DOCS_URL } from '@/config/constants';
import { convertBigNumbersToReadable } from '@/utils';

import s from './styles.module.scss';

interface SubheaderProps {
  totalClaimable: string;
  totalDeposits: string;
  totalCombinedApr: string;
  lpPriceInUsd: string;
}

export const Subheader: FC<SubheaderProps> = ({
  totalClaimable,
  totalDeposits,
  totalCombinedApr,
  lpPriceInUsd,
}) => {
  return (
    <div className={s.subheader_wrapper}>
      <div className={s.totals}>
        <div className={s.total_item}>
          <WalletGreenIcon />
          <div className={s.total}>
            <Typography type="h3">${new BigNumber(totalClaimable).toFixed(2)}</Typography>
            <Typography isUpper className={s.label} type="sub2">
              Total Claimable
            </Typography>
          </div>
        </div>
        <div className={s.total_item}>
          <WalletPinkIcon />
          <div className={s.total}>
            <Typography type="h3">
              ${new BigNumber(totalDeposits).multipliedBy(lpPriceInUsd).toFixed(2)}
            </Typography>
            <Typography isUpper className={s.label} type="sub2">
              Total Deposits
            </Typography>
          </div>
        </div>
        <div className={s.total_item}>
          <MountainIcon />
          <div className={s.total}>
            <Typography type="h3">%{convertBigNumbersToReadable(totalCombinedApr)}</Typography>
            <Typography isUpper className={s.label} type="sub2">
              Average Hive APR
            </Typography>
          </div>
        </div>
      </div>
      <div className={s.fund}>
        <div className={s.powered}>
          <Typography type="h6" isUpper>
            Powered by:
          </Typography>
          <PoweredByLogo />
        </div>
        <Button
          href={XB3_DOCS_URL}
          startIcon={<BookIcon />}
          className={s.btn_fund}
          color="darkBlue"
        >
          Docs
        </Button>
      </div>
    </div>
  );
};
