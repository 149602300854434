import api from '@/api/axios';
import { Vault } from '@/types';

export const hiveApi = {
  getVaults: () =>
    api
      .get<Vault[]>('/hive/vaults/')
      .then((res) => res.data)
      .catch(() => [] as Vault[]),
};
