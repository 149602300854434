import { FC } from 'react';
import cn from 'clsx';

import { PendingRewardsIcon, RewardsIcon } from '@/assets/img/icons';
import { Button, InfoPopover, Typography } from '@/components';
import { useShallowSelector } from '@/hooks';
import userSelector from '@/store/user/selectors';
import { convertBigNumbersToReadable } from '@/utils';

import s from './styles.module.scss';

export interface ReferralRewardsProps {
  claimableRewards: number;
  pendingRewards: number;
  claimRewards: () => void;
  isClaimingInProccess?: boolean;
  className?: string;
}

export const ReferralRewards: FC<ReferralRewardsProps> = ({
  className,
  claimableRewards,
  pendingRewards,
  claimRewards,
  isClaimingInProccess = false,
}) => {
  const {
    lockup: { lockEnds, lockedBalance },
  } = useShallowSelector(userSelector.getUser);
  const currentTime = Math.floor(Date.now() / 1000);
  const isUserFirstShouldClaimLockup = currentTime > +lockEnds && +lockedBalance > 0;

  return (
    <div className={cn(s.referralRewards, className)}>
      <div className={s.statistic}>
        <div className={s.item}>
          <div className={s.itemIcon}>
            <RewardsIcon />
          </div>
          <div className={s.right}>
            <Typography weight="medium" type="h2" className={s.itemTitle}>
              ${convertBigNumbersToReadable(claimableRewards)}
            </Typography>
            <Typography type="label1" className={s.itemSubtitle}>
              Claimable Rewards
            </Typography>
          </div>
        </div>
        <div className={s.item}>
          <div className={s.itemIcon}>
            <PendingRewardsIcon />
          </div>
          <div className={s.right}>
            <Typography weight="medium" type="h2" className={s.itemTitle}>
              ${convertBigNumbersToReadable(pendingRewards)}
            </Typography>
            <Typography type="label1" className={s.itemSubtitle}>
              Pending Rewards
            </Typography>
          </div>
        </div>
      </div>
      <Button
        loading={isClaimingInProccess}
        disabled={claimableRewards === 0 || isUserFirstShouldClaimLockup}
        className={s.claimButton}
        onClick={claimRewards}
        endIcon={
          isUserFirstShouldClaimLockup ? (
            <InfoPopover>First pick up your lock on the Xb3 Lockup page</InfoPopover>
          ) : undefined
        }
      >
        Claim Referral Rewards
      </Button>
      <Typography type="body2" color="blue">
        *Note - Your referred users will need to claim first before your referral rewards become
        claimable.
      </Typography>
    </div>
  );
};
