import { call, put, select, takeLatest } from 'typed-redux-saga';

import { ContractsNames } from '@/config';
import apiActions from '@/store/api/actions';
import userSelector from '@/store/user/selectors';
import { ReferralProgramAbi } from '@/types/contracts/ReferralProgramAbi';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { getIsUserRegistered } from '../actions';
import actionTypes from '../actionTypes';
import { updateReferralsState } from '../reducer';

export function* getIsUserRegisteredSaga({
  type,
  payload: { web3Provider },
}: ReturnType<typeof getIsUserRegistered>) {
  yield* put(apiActions.request(type));

  try {
    const [abi, address] = getContractDataByHisName(ContractsNames.referralProgram);
    const referralProgrammContract: ReferralProgramAbi = yield new web3Provider.eth.Contract(
      abi,
      address,
    );
    const userAddress = yield* select(userSelector.getProp('address'));

    const userInfo = yield* call(referralProgrammContract.methods.users(userAddress).call);
    yield* put(updateReferralsState({ isUserRegistered: userInfo.exists }));

    yield* put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_IS_USER_REGISTERED, getIsUserRegisteredSaga);
}
