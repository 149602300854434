import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InfoState } from '@/types';

const initialState: InfoState = {
  currentCirculatingSupply: '0',
  totalLockedXb3: '0',
  totalLockedXb3Percent: '0',
  maxSupply: '40000',
  averageLockTime: 0,
};

export const infoReducer = createSlice({
  name: 'info',
  initialState,
  reducers: {
    updateInfoState: (state, action: PayloadAction<Partial<InfoState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateInfoState } = infoReducer.actions;

export default infoReducer.reducer;
