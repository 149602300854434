import { FC } from 'react';
import BigNumber from 'bignumber.js';
import cn from 'clsx';

import { BookIcon, ReferralBlueIcon } from '@/assets/img/icons';
import { Button, Typography } from '@/components';
import { XB3_DOCS_URL } from '@/config/constants';

import s from './styles.module.scss';

export interface SubheaderProps {
  tokenPrice: number;
  userBalance: string;
  className?: string;
  withBalance?: boolean;
}

export const Subheader: FC<SubheaderProps> = ({
  tokenPrice,
  userBalance,
  className,
  withBalance = true,
}) => {
  return (
    <div className={cn(s.subheader, className)}>
      <div className={s.referral_wrapper}>
        <div className={s.referral_title__wrapper}>
          <ReferralBlueIcon style={{ minWidth: 64 }} />
          <Typography isUpper className={s.title} spacing={0.15} type="h6">
            Earn up to 5% of the yield earned by people you refer.
          </Typography>
        </div>
        <Button className={s.referral_btn} color="darkBlue" variant="outlined" to="/referrals">
          Get Referral Code
        </Button>
      </div>
      {withBalance && (
        <div className={s.xbf_stats}>
          <div className={s.xbf_stat}>
            <Typography isUpper spacing={0.1} type="sub1">
              My XB3 Balance
            </Typography>
            <Typography type="h1">{new BigNumber(userBalance).toFormat(2)}</Typography>
            <Typography isUpper spacing={0.1} type="sub1">
              ${new BigNumber(tokenPrice).multipliedBy(userBalance).toFormat(2)}
            </Typography>
          </div>
          <Button
            className={s.docs_btn}
            color="darkBlue"
            startIcon={<BookIcon />}
            href={XB3_DOCS_URL}
          >
            Docs
          </Button>
        </div>
      )}
    </div>
  );
};
