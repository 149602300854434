import { AbiItem } from 'web3-utils';

import { Chains } from '@/types';

import { getWeb3 } from '.';

export const createContract = async (abi: AbiItem[], address: string, forceMainnet = false) => {
  const web3 = await getWeb3(Chains.Ethereum, forceMainnet);
  return new web3.eth.Contract(abi, address);
};
