import { call, put, select, takeLatest } from 'typed-redux-saga';

import { referralsApi } from '@/api/referralsApi';
import apiActions from '@/store/api/actions';
import userSelector from '@/store/user/selectors';

import { getReferralStats } from '../actions';
import actionTypes from '../actionTypes';
import { initialStatistic, updateReferralsState } from '../reducer';

export function* getReferralStatsSaga({ type }: ReturnType<typeof getReferralStats>) {
  yield* put(apiActions.request(type));

  try {
    const userAddress = yield* select(userSelector.getProp('address'));
    const referralStats = yield* call(referralsApi.getReferralStats, userAddress);

    if (!referralStats) {
      yield* put(updateReferralsState({ statistic: initialStatistic }));
      yield* put(apiActions.success(type));
      return;
    }

    yield* put(
      updateReferralsState({
        statistic: {
          ...referralStats,
          createdAt: new Date(referralStats.createdAt).valueOf().toString(),
        },
      }),
    );

    yield* put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_REFERRAL_STATS, getReferralStatsSaga);
}
