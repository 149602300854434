import BigNumber from 'bignumber.js';
import { call, put, takeLatest } from 'typed-redux-saga';

import { ContractsNames } from '@/config';
import { BOOST_MAX_LEVEL } from '@/config/constants';
import apiActions from '@/store/api/actions';
import { VotingStakingRewardsAbi } from '@/types';
import { fromDecimals } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { getUserBoostLevel } from '../actions';
import actionTypes from '../actionTypes';
import { updateUserState } from '../reducer';

export function* getUserBoostLevelSaga({
  type,
  payload: { address, web3Provider },
}: ReturnType<typeof getUserBoostLevel>) {
  yield* put(apiActions.request(type));
  const [votingStakingRewardsAbi, votingStakingRewardsAddress] = getContractDataByHisName(
    ContractsNames.votingStakingRewards,
  );

  try {
    const votingStakingRewardsContract: VotingStakingRewardsAbi =
      yield new web3Provider.eth.Contract(votingStakingRewardsAbi, votingStakingRewardsAddress);
    const boostLevel = yield* call(
      votingStakingRewardsContract.methods.calculateBoostLevel(address).call,
    );

    const calculateBoostLevelPercentage = new BigNumber(fromDecimals(boostLevel))
      .minus(1)
      .multipliedBy(100)
      .dividedBy(BOOST_MAX_LEVEL - 1)
      .toNumber();

    yield put(updateUserState({ boostLevel: calculateBoostLevelPercentage }));

    yield* put(apiActions.success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_USER_BOOST_LEVEL, getUserBoostLevelSaga);
}
