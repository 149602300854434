import { fork } from 'redux-saga/effects';

import claimSaga from './claim';
import depositCvxCrvSaga from './depositCvxCrv';
import depositLpSaga from './depositLp';
import getVaultsDataSaga from './getVaultsData';
import unwrapSaga from './unwrap';
import withdrawSaga from './withdraw';

export default function* hiveSagas() {
  yield fork(depositLpSaga);
  yield fork(withdrawSaga);
  yield fork(claimSaga);
  yield fork(unwrapSaga);
  yield fork(getVaultsDataSaga);
  yield fork(depositCvxCrvSaga);
}
