const GET_TOKEN_BALANCE = 'GET_TOKEN_BALANCE';
const GET_VE_TOKEN_BALANCE = 'GET_VE_TOKEN_BALANCE';
const GET_FTM_BALANCE = 'GET_FTM_BALANCE';
const GET_USER_MAIN_INFO = 'GET_USER_MAIN_INFO';
const GET_USER_LOCKUP_DATA = 'GET_USER_LOCKUP_DATA';
const GET_USER_BOOST_LEVEL = 'GET_USER_BOOST_LEVEL';
const GET_USER_HIVE_REWARDS = 'GET_USER_HIVE_REWARDS';
const GET_BONUS_CAMPAIGN_REWARD = 'GET_BONUS_CAMPAIGN_REWARD';
const GET_LP_TOKEN_BALANCE = 'GET_LP_TOKEN_BALANCE';
const GET_CRV_TOKEN_BALANCE = 'GET_CRV_TOKEN_BALANCE';
const GET_CVX_CRV_TOKEN_BALANCE = 'GET_CVX_CRV_TOKEN_BALANCE';
const GET_USER_VAULTS_DATA = 'GET_USER_VAULTS_DATA';
const GET_MIGRATION_INFO = 'GET_MIGRATION_INFO';
const CLAIM_MIGRATION_TOKENS = 'CLAIM_MIGRATION_TOKENS';
const GET_USER_XB3_REWARDS = 'GET_USER_XB3_REWARDS';
const GET_USER_SIGNATURE_INFO = 'GET_USER_SIGNATURE_INFO';
const SIGN_TERMS_OF_USE = 'SIGN_TERMS_OF_USE';

const APPROVE = 'APPROVE';

export default {
  GET_TOKEN_BALANCE,
  GET_VE_TOKEN_BALANCE,
  GET_FTM_BALANCE,
  GET_USER_MAIN_INFO,
  GET_USER_LOCKUP_DATA,
  GET_USER_BOOST_LEVEL,
  GET_USER_HIVE_REWARDS,
  GET_BONUS_CAMPAIGN_REWARD,
  GET_LP_TOKEN_BALANCE,
  GET_CRV_TOKEN_BALANCE,
  GET_CVX_CRV_TOKEN_BALANCE,
  GET_USER_VAULTS_DATA,
  APPROVE,
  GET_MIGRATION_INFO,
  CLAIM_MIGRATION_TOKENS,
  GET_USER_XB3_REWARDS,
  GET_USER_SIGNATURE_INFO,
  SIGN_TERMS_OF_USE,
};
