import { call, put, select, takeLatest } from 'typed-redux-saga';

import { ContractsNames } from '@/config';
import apiActions from '@/store/api/actions';
import userActionTypes from '@/store/user/actionTypes';
import { getUserHiveRewardsSaga } from '@/store/user/sagas/getUserHiveRewards';
import { getUserXb3RewardsSaga } from '@/store/user/sagas/getUserXb3Rewards';
import userSelector from '@/store/user/selectors';
import { notify } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { claimAllRewards } from '../actions';
import actionTypes from '../actionTypes';

export function* claimAllRewardsSaga({
  type,
  payload: { web3Provider, type: claimType },
}: ReturnType<typeof claimAllRewards>) {
  yield* put(apiActions.request(type));

  const isHiveContract = claimType === 'hive';

  const [abi, address] = getContractDataByHisName(
    isHiveContract ? ContractsNames.convexVault : ContractsNames.votingStakingRewards,
  );
  const userAddress = yield* select(userSelector.getProp('address'));

  try {
    const contract = new web3Provider.eth.Contract(abi, address);

    if (isHiveContract) {
      // CHECK IF CAN SEND
      yield* call(contract.methods.getReward(userAddress).estimateGas, { from: userAddress });

      yield* call(contract.methods.getReward(userAddress).send, { from: userAddress });
      yield* call(getUserHiveRewardsSaga, {
        type: userActionTypes.GET_USER_HIVE_REWARDS,
        payload: { web3Provider, address: userAddress },
      });
    } else {
      // CHECK IF CAN SEND
      yield* call(contract.methods.getReward().estimateGas, { from: userAddress });

      yield* call(contract.methods.getReward().send, { from: userAddress });
      yield* call(getUserXb3RewardsSaga, {
        type: userActionTypes.GET_USER_XB3_REWARDS,
        payload: { web3Provider, address: userAddress },
      });
    }

    yield* put(apiActions.success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    notify.error('Something may go wrong. Please check your inputs.');
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CLAIM_ALL_REWARDS, claimAllRewardsSaga);
}
