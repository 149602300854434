import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import BigNumber from 'bignumber.js';

import { PigBigIcon, PigSmallIcon, Xb3Logo } from '@/assets/img/icons';
import { Button, Typography } from '@/components';
import { XB3_DOCS_URL } from '@/config/constants';
import { useShallowSelector } from '@/hooks';
import { useWalletConnectorContext } from '@/services';
import uiSelector from '@/store/ui/selectors';
import { claimMigrationTokens, getMigrationInfo } from '@/store/user/actions';
import actionTypes from '@/store/user/actionTypes';
import userSelector from '@/store/user/selectors';
import { RequestStatus } from '@/types';
import { convertBigNumbersToReadable, fromDecimals } from '@/utils';

import s from './ClaimXb3.module.scss';

const ClaimXb3: FC = () => {
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();

  const {
    address,
    migrationInfo: {
      xbeBalance,
      vsrStaked,
      vsrReward,
      bcReward,
      referralReward,
      sushiVaultEarned,
      frauxVaultEarned,
      crvCvxVaultEarned,
    },
    isUserMigrateTokens,
  } = useShallowSelector(userSelector.getUser);
  const { [actionTypes.CLAIM_MIGRATION_TOKENS]: claimMigrationTokensReqStatus } =
    useShallowSelector(uiSelector.getUI);

  const handleClaimTokens = () => {
    dispatch(claimMigrationTokens({ web3Provider: walletService.Web3() }));
  };

  const claimAbleXb3Amount = useMemo(() => {
    return new BigNumber(xbeBalance)
      .plus(vsrStaked)
      .plus(vsrReward)
      .plus(bcReward)
      .plus(referralReward)
      .plus(
        new BigNumber(sushiVaultEarned)
          .plus(frauxVaultEarned)
          .plus(crvCvxVaultEarned)
          .multipliedBy(0.79),
      )
      .toFixed(2);
  }, [
    bcReward,
    crvCvxVaultEarned,
    frauxVaultEarned,
    referralReward,
    sushiVaultEarned,
    vsrStaked,
    vsrReward,
    xbeBalance,
  ]);

  const isClaimButtonDisabled = !address || !claimAbleXb3Amount || isUserMigrateTokens;
  const isClaimInProcess = claimMigrationTokensReqStatus === RequestStatus.REQUEST;

  useEffect(() => {
    if (address) {
      dispatch(getMigrationInfo({ web3Provider: walletService.Web3() }));
    }
  }, [dispatch, address]);

  return (
    <section className={s.page_wrapper}>
      <div className={s.content}>
        <div className={s.left}>
          <PigBigIcon />
          <div>
            <Typography type="h4" isUpper className={s.title}>
              Claim your xb3
            </Typography>
            <Typography type="sub2">
              This page is only for old token holders to migrate to $XB3. For more information on
              the token migration - please visit&nbsp;
              <a target="_blank" rel="noreferrer noopener" href={XB3_DOCS_URL}>
                docs.xb3.finance
              </a>
            </Typography>
          </div>
        </div>
        <div className={s.right}>
          <Typography type="sub1" isUpper spacing={0.1} className={s.balanceTitle}>
            My Claimable XB3 Balance
          </Typography>
          <p className={s.balance}>
            <Xb3Logo className={s.xbfLogo} />
            <Typography type="body1" className={s.balanceAmount}>
              {convertBigNumbersToReadable(fromDecimals(claimAbleXb3Amount)) === 'NaN'
                ? 0
                : convertBigNumbersToReadable(fromDecimals(claimAbleXb3Amount))}
            </Typography>
          </p>
          <Button
            disabled={isClaimButtonDisabled}
            loading={isClaimInProcess}
            color="darkBlue"
            startIcon={<PigSmallIcon />}
            onClick={handleClaimTokens}
          >
            Claim
          </Button>
          <p className={s.subtitle}>Note: This amount includes locked and free circulating XB3</p>
        </div>
      </div>
    </section>
  );
};

export default ClaimXb3;
