import { Referral } from '@/types';

export const sortReferrals = (sortType: string, referrals: Array<Referral>) => {
  switch (sortType) {
    case 'byLiquidityAsc':
      return [...referrals].sort((a: Referral, b: Referral) => b.deposits - a.deposits);
    case 'byLiquidityDesc':
      return [...referrals].sort((a: Referral, b: Referral) => a.deposits - b.deposits);
    case 'byRegTimeAsc':
      return [...referrals].sort(
        (a: Referral, b: Referral) => Number(a.createdAt) - Number(b.createdAt),
      );
    case 'byRegTimeDesc':
      return [...referrals].sort(
        (a: Referral, b: Referral) => Number(b.createdAt) - Number(a.createdAt),
      );
    default:
      return referrals;
  }
};
