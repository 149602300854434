import BigNumber from 'bignumber.js';
import { call, put, takeLatest } from 'typed-redux-saga';

import { baseInfoApi } from '@/api';
import { ContractsNames } from '@/config';
import apiActions from '@/store/api/actions';
import { updateInfoState } from '@/store/info/reducer';
import { Erc20Abi, InflationAbi, VeXb3TokenAbi } from '@/types';
import { createContract, fromDecimals } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { getAdditionalInfo } from '../actions';
import actionTypes from '../actionTypes';

export function* getAdditionalInfoSaga({ type }: ReturnType<typeof getAdditionalInfo>) {
  yield* put(apiActions.request(type));

  const [veXb3TokenAbi, veXb3TokenAddress] = getContractDataByHisName(ContractsNames.veXb3Token);
  const [, treasuryAddress] = getContractDataByHisName(ContractsNames.treasury);
  const [xb3TokenAbi, xb3TokenAddress] = getContractDataByHisName(ContractsNames.xb3Token);
  const [xbfInflationAbi, xbfInflationAddress] = getContractDataByHisName(ContractsNames.inflation);

  const xb3TokenContract: Erc20Abi = yield createContract(xb3TokenAbi, xb3TokenAddress);
  const veXb3TokenContract: VeXb3TokenAbi = yield createContract(veXb3TokenAbi, veXb3TokenAddress);
  const xbfInflationContract: InflationAbi = yield createContract(
    xbfInflationAbi,
    xbfInflationAddress,
  );

  try {
    const totalLockedXb3 = yield* call(veXb3TokenContract.methods.lockedSupply().call);
    const totalMinted = yield* call(xbfInflationContract.methods.totalMinted().call);
    const averageLockTime = yield* call(baseInfoApi.getAverageLockTime);
    const treasuryBalance = yield* call(xb3TokenContract.methods.balanceOf(treasuryAddress).call);

    yield* put(
      updateInfoState({
        currentCirculatingSupply: new BigNumber(fromDecimals(totalMinted))
          .minus(fromDecimals(treasuryBalance))
          .toFixed(),
        totalLockedXb3: fromDecimals(totalLockedXb3),
        totalLockedXb3Percent: new BigNumber(totalLockedXb3)
          .dividedBy(totalMinted)
          .multipliedBy(100)
          .toString(),
        averageLockTime: averageLockTime.data.days,
      }),
    );

    yield* put(apiActions.success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_INFO, getAdditionalInfoSaga);
}
