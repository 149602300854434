export * from './connect';
export * from './store';
export * from './contracts';
export * from './api';

export type DateLike = string | number | Date;
export type TNullable<T> = T | null;
export type TOptionable<T> = T | undefined;

// eslint-disable-next-line no-shadow
export enum WalletProviders {
  metamask = 'MetaMask',
}

// eslint-disable-next-line no-shadow
export enum RoundingModes {
  up,
  down,
}
