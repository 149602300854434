import { FC, useCallback } from 'react';
import cn from 'clsx';
import Range from 'rc-slider';

import { Typography } from '@/components/Typography';

import 'rc-slider/assets/index.css';
import s from './styles.module.scss';

export interface RangeSliderProps {
  title: string;
  subtitle?: string;
  min: number;
  max: number;
  step: number;
  defaultValue?: number;
  onChange: (value: number) => void;
  color?: 'blue' | 'purple';
  className?: string;
  disabled?: boolean;
}
interface LooseObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const RangeSlider: FC<RangeSliderProps> = ({
  title,
  min,
  max,
  step = 1,
  defaultValue = 0,
  onChange,
  color = 'purple',
  className,
  disabled = false,
  subtitle,
}) => {
  const markStyle = {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '12px',
    marginTop: 25,
    color: '#32335A',
  };
  const hideMarkStyle = {
    fontWeight: 500,
    fontSize: 5,
    marginTop: 20,
    color: '#32335A',
  };
  const config = {
    railStyle: {
      background: 'rgba(57, 111, 241, 0.1)',
      height: 10,
    },
    trackStyle: {
      transition: '0.3s ease background-color',
      height: 10,
      background: color === 'purple' ? '#BB83ED' : '#1969FF',
    },
    handleStyle: {
      height: 31,
      width: 31,
      borderRadius: '50%',
      border: 'none',
      background: '#ffffff',
      boxShadow: '0px 7px 20px rgba(187, 131, 237, 0.25), 0px 5px 50px rgba(0, 0, 0, 0.05)',
      opacity: 1,
      marginTop: -10,
    },
    dotStyle: {
      display: 'none',
    },
    marks: new Array(max).fill(1).reduce(
      (total: LooseObject, mark: number, index: number) => {
        const marksToDisplay = [16, 32, 48, 68, 84, 100];
        const currentMark = mark * (index + 1);
        const isToShowMark = marksToDisplay.includes(currentMark * 4);
        total[index + 1] = {
          label: isToShowMark ? currentMark * 4 : '|',
          style: isToShowMark ? markStyle : hideMarkStyle,
        };
        return total;
      },
      {
        0: {
          label: 1,
          style: markStyle,
        },
      },
    ),
  };

  const handleChange = useCallback(
    (value: any) => {
      onChange(value === 0 ? 1 : value * step);
    },
    [onChange, step],
  );

  return (
    <>
      <div className={s.slider_header}>
        <Typography className={s.title} type="body2">
          {title.toUpperCase()}
        </Typography>
        {subtitle && (
          <Typography className={s.subtitle} type="body2">
            {subtitle}
          </Typography>
        )}
      </div>
      <div className={s.slider_wrapper}>
        <Range
          className={cn(s.range_slider, className)}
          min={min}
          max={max}
          dots={false}
          step={step}
          defaultValue={defaultValue}
          onChange={(value) => handleChange(value)}
          railStyle={config.railStyle}
          trackStyle={config.trackStyle}
          handleStyle={config.handleStyle}
          dotStyle={config.dotStyle}
          marks={config.marks}
          disabled={disabled}
        />
      </div>
    </>
  );
};
