import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import BigNumber from 'bignumber.js';
import { millisecondsToSeconds } from 'date-fns';

import { Xb3Logo } from '@/assets/img/icons';
import {
  Button,
  DetailedInfoPopover,
  InfoPopover,
  Input,
  LabelSection,
  RangeSlider,
  Typography,
} from '@/components';
import { useShallowSelector } from '@/hooks';
import { useWalletConnectorContext } from '@/services';
import { lock as lockAction, unlock as unlockAction } from '@/store/lockup/actions';
import actionTypes from '@/store/lockup/actionTypes';
import lockupSelector from '@/store/lockup/selectors';
import uiSelector from '@/store/ui/selectors';
import userSelector from '@/store/user/selectors';
import { LockupState, RequestStatus, State, UserState } from '@/types';
import { convertBigNumbersToReadable } from '@/utils';

import s from './styles.module.scss';

export const Lockup: FC = () => {
  const { walletService } = useWalletConnectorContext();
  const dispatch = useDispatch();

  const { [actionTypes.LOCK]: lockRequestStatus, [actionTypes.UNLOCK]: unlockRequestStatus } =
    useShallowSelector(uiSelector.getUI);

  const { totalLocked, lockedApr } = useShallowSelector<State, LockupState>(
    lockupSelector.getLockup,
  );

  const { address, lockup, boostLevel, xb3Balance } = useShallowSelector<State, UserState>(
    userSelector.getUser,
  );

  const [lockAmount, setLockAmount] = useState('');
  const [lockInputError, setLockInputError] = useState(false);

  const [lockPeriod, setLockPeriod] = useState(48);

  const unlockAmount = useMemo(
    () => new BigNumber(lockup.lockedBalance).toFixed(),
    [lockup.lockedBalance],
  );

  // const availableToLock = useMemo(() => {
  //   const availableLeft = new BigNumber(stakedTokens).minus(lockup.lockedBalance);
  //   return availableLeft.isGreaterThan(0) ? availableLeft : 0;
  // }, [stakedTokens, lockup.lockedBalance]);

  const handleChangeLockAmount = useCallback(
    (inputValue: string) => {
      setLockAmount(inputValue);
      if (+inputValue > +xb3Balance) {
        setLockInputError(true);
      } else {
        setLockInputError(false);
      }
    },
    [xb3Balance],
  );

  const isLockButtonDisabled = useMemo(() => {
    return (
      lockAmount === '' ||
      lockAmount === '0' ||
      lockInputError ||
      new BigNumber(lockup.currentLockAmount).isGreaterThan(0)
    );
  }, [lockup.currentLockAmount, lockAmount, lockInputError]);

  const isUnlockButtonDisabled = useMemo(() => {
    return (
      new BigNumber(unlockAmount).isEqualTo(0) ||
      new BigNumber(millisecondsToSeconds(Date.now())).isLessThan(lockup.lockEnds)
    );
  }, [lockup.lockEnds, unlockAmount]);

  const lockRequestInProcess = useMemo(
    () => lockRequestStatus === RequestStatus.REQUEST,
    [lockRequestStatus],
  );

  const unlockRequestInProcess = useMemo(
    () => unlockRequestStatus === RequestStatus.REQUEST,
    [unlockRequestStatus],
  );

  const userPercentOfTotalLocked = useMemo(() => {
    if (+totalLocked === 0) return 0;
    return new BigNumber(lockup.lockedBalance)
      .multipliedBy(100)
      .dividedBy(totalLocked)
      .toFixed(2)
      .toString();
  }, [totalLocked, lockup.lockedBalance]);

  const handleLock = useCallback(() => {
    dispatch(
      lockAction({
        amount: lockAmount,
        lockPeriod: lockPeriod === 0 ? 1 : lockPeriod,
        web3Provider: walletService.Web3(),
      }),
    );
  }, [dispatch, lockAmount, lockPeriod, walletService]);

  const handleUnlock = useCallback(() => {
    dispatch(
      unlockAction({
        web3Provider: walletService.Web3(),
      }),
    );
  }, [dispatch, walletService]);

  useEffect(() => {
    if (address.length === 0 || lockRequestStatus === RequestStatus.SUCCESS) {
      setLockAmount('');
    }
  }, [address.length, lockRequestStatus, unlockRequestStatus]);

  return (
    <div className={s.lockup_wrapper}>
      <LabelSection
        icon={<Xb3Logo />}
        title="XB3 LOCKUP"
        background="pink"
        endContent={<DetailedInfoPopover />}
      />
      <div className={s.locked_stats}>
        <div className={s.stats_item}>
          <Typography className={s.label} color="label2" type="label1">
            Your Locked assets
          </Typography>
          <Typography type="h2">
            {new BigNumber(lockup.lockedBalance).decimalPlaces(4, 1).toString()}
          </Typography>
        </div>
        <div className={s.stats_item}>
          <Typography className={s.label} color="label2" type="label1">
            % Of Total Locked
          </Typography>
          <Typography type="h2">
            {convertBigNumbersToReadable(userPercentOfTotalLocked)}%
          </Typography>
        </div>
        <div className={s.stats_item}>
          <Typography className={s.label} color="label2" type="label1">
            Boost levels
          </Typography>
          <Typography type="h2">{boostLevel.toFixed(2)}%</Typography>
        </div>
        <div className={s.stats_item}>
          <Typography className={s.label} color="label2" type="label1">
            Locked APR
          </Typography>
          <Typography type="h2">{convertBigNumbersToReadable(lockedApr)}%</Typography>
        </div>
      </div>
      <RangeSlider
        title="Lock for period of (Weeks)"
        min={0}
        max={25}
        step={4}
        defaultValue={lockPeriod / 4}
        onChange={(value) => setLockPeriod(value)}
      />
      <Input
        className={s.input}
        onChange={handleChangeLockAmount}
        value={lockAmount}
        label="Lock"
        onlyNumbers
        labelEnd={
          <Typography color="default" className={s.balance} type="body2">
            Balance: <span>{new BigNumber(xb3Balance).toFixed()}</span>
          </Typography>
        }
        color="gray"
        placeholder="0.00"
        startIcon={<Xb3Logo />}
      />
      <Button
        color="purple"
        className={s.btn}
        onClick={handleLock}
        disabled={isLockButtonDisabled}
        loading={lockRequestInProcess}
      >
        Approve & Lock
      </Button>
      <Input
        className={s.input}
        disabled
        value={unlockAmount}
        label={
          <div className={s.unlockLabel}>
            <Typography weight="medium" type="body2">
              Unlock
            </Typography>
            <InfoPopover align="left" className={s.unlockLabelPopup}>
              Please note that you will only be allowed to unlock your XB3 after your lock period
              has concluded
            </InfoPopover>
          </div>
        }
        onlyNumbers
        labelEnd={
          <Typography color="default" className={s.balance} type="body2">
            Balance: <span>{unlockAmount}</span>
          </Typography>
        }
        color="gray"
        placeholder="0.00"
        startIcon={<Xb3Logo />}
      />
      <Button
        color="purple"
        className={s.btn}
        onClick={handleUnlock}
        disabled={isUnlockButtonDisabled}
        loading={unlockRequestInProcess}
      >
        Unlock
      </Button>
    </div>
  );
};
