import { FC, useMemo } from 'react';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import cn from 'clsx';

import { Typography } from '@/components';
import { useShallowSelector } from '@/hooks';
import hiveSelector from '@/store/hive/selectors';
import ratesSelector from '@/store/rates/selectors';
import userSelector from '@/store/user/selectors';

import { Claim } from './components/Claim';
import { Stake } from './components/Stake';
import { Subheader } from './components/Subheader';
import { links } from './mock';

import s from './Hive.module.scss';

const Hive: FC = () => {
  const location = useParams();
  const rates = useShallowSelector(ratesSelector.getRates);
  const { totalCombinedApr } = useShallowSelector(hiveSelector.getHive);

  const { hiveDeposits, hiveRewards: userRewards } = useShallowSelector(userSelector.getUser);
  const totalDeposits = hiveDeposits[0];
  const hiveRewardEarnedInUsd = useMemo(
    () =>
      new BigNumber(new BigNumber(userRewards.xb3).multipliedBy(rates.xb3.price))
        .plus(new BigNumber(userRewards.crv).multipliedBy(rates.crv.price))
        .plus(new BigNumber(userRewards.cvx).multipliedBy(rates.cvx.price))
        .toFixed(2),
    [rates.crv.price, rates.cvx.price, rates.xb3.price, userRewards],
  );

  const slug = useMemo(() => location['*'], [location]);

  return (
    <div className={s.hive_wrapper}>
      <Subheader
        totalClaimable={hiveRewardEarnedInUsd}
        totalDeposits={totalDeposits}
        totalCombinedApr={totalCombinedApr}
        lpPriceInUsd={rates.crvCvxCrvLp.price.toString()}
      />
      <div className="card">
        <div className="card_header">
          {links.map(({ slug: linkSlug }) => (
            <Link key={linkSlug} className={s.link} to={`/hive/${linkSlug}`}>
              <Typography
                className={cn(s.linkTitle, s[linkSlug], { [s.linkTitleActive]: linkSlug === slug })}
                type="body1"
                weight="normal"
              >
                {linkSlug}
              </Typography>
            </Link>
          ))}
        </div>
        <div className={s.body}>
          <Routes>
            <Route path="stake" element={<Stake />} />
            <Route path="claim" element={<Claim />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};
export default Hive;
