import { fork } from 'redux-saga/effects';

import getIsUserRegisteredListener from './getIsUserRegistered';
import getMyReferralCodeListener from './getMyReferralCode';
import getReferralStatsListener from './getReferralStats';
import getUserReferralNetworkListener from './getUserReferralNetwork';
import hitReferrerListener from './hitReferrer';
import registerListener from './register';

export default function* referralsSagas() {
  yield fork(getIsUserRegisteredListener);
  yield fork(registerListener);
  yield fork(getUserReferralNetworkListener);
  yield fork(getReferralStatsListener);
  yield fork(getMyReferralCodeListener);
  yield fork(getMyReferralCodeListener);
  yield fork(hitReferrerListener);
}
