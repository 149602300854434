import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import createSagaMiddleware from 'redux-saga';

import hiveActionTypes from './hive/actionTypes';
import infoActionTypes from './info/actionTypes';
import lockupActionTypes from './lockup/actionTypes';
import referralsActionTypes from './referrals/actionTypes';
import rewardsActionTypes from './rewards/actionTypes';
import userActionTypes from './user/actionTypes';
import reducer from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['provider'],
};

const referralsPersistConfig = {
  key: 'referrals',
  storage: sessionStorage,
  whitelist: ['isUserRegistered'],
};

const reducers = {
  ...reducer,
  user: persistReducer(userPersistConfig, reducer.user),
  referrals: persistReducer(referralsPersistConfig, reducer.referrals),
};

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          ...Object.values(infoActionTypes),
          ...Object.values(lockupActionTypes),
          ...Object.values(userActionTypes),
          ...Object.values(rewardsActionTypes),
          ...Object.values(referralsActionTypes),
          ...Object.values(hiveActionTypes),
        ],
      },
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
const persistor = persistStore(store);

export default { store, persistor };
