import { call, put, takeLatest } from 'typed-redux-saga';

import { ContractsNames } from '@/config';
import { error, request, success } from '@/store/api/actions';
import { LpTokenAbi } from '@/types';
import { fromDecimals } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { getCvxCrvTokenBalance } from '../actions';
import actionTypes from '../actionTypes';
import { updateUserState } from '../reducer';

export function* getCvxCrvTokenBalanceSaga({
  type,
  payload: { web3Provider, address },
}: ReturnType<typeof getCvxCrvTokenBalance>) {
  yield put(request(type));

  const [cvxCrvTokenAbi, cvxCrvTokenAddress] = getContractDataByHisName(ContractsNames.cvxCrvToken);

  try {
    const lpTokenContract: LpTokenAbi = yield new web3Provider.eth.Contract(
      cvxCrvTokenAbi,
      cvxCrvTokenAddress,
    );
    const balance = yield* call(lpTokenContract.methods.balanceOf(address).call);
    const decimals = yield* call(lpTokenContract.methods.decimals().call);

    yield put(updateUserState({ cvxCrvTokenBalance: fromDecimals(balance, +decimals) }));

    yield put(success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_CVX_CRV_TOKEN_BALANCE, getCvxCrvTokenBalanceSaga);
}
