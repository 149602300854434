import BigNumber from 'bignumber.js';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { ContractsNames } from '@/config';
import { MAX_UINT } from '@/config/constants';
import apiActions from '@/store/api/actions';
import userActionTypes from '@/store/user/actionTypes';
import { updateUserState } from '@/store/user/reducer';
import { approveSaga } from '@/store/user/sagas/approve';
import userSelector from '@/store/user/selectors';
import { ConvexVaultAbi, Erc20Abi } from '@/types';
import { fromDecimals, notify, toDecimals } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { depositLp } from '../actions';
import actionTypes from '../actionTypes';

import { getVaultsDataSaga } from './getVaultsData';

export function* depositLpSaga({
  type,
  payload: { web3Provider, amount },
}: ReturnType<typeof depositLp>) {
  yield* put(apiActions.request(type));

  const { lpTokenBalance, address } = yield* select(userSelector.getUser);

  const [lpTokenAbi, lpTokenAddress] = getContractDataByHisName(ContractsNames.lpToken);
  const [vaultAbi, vaultAddress] = getContractDataByHisName(ContractsNames.convexVault);

  try {
    const lpTokenContract: Erc20Abi = yield new web3Provider.eth.Contract(
      lpTokenAbi,
      lpTokenAddress,
    );
    const vaultContract: ConvexVaultAbi = yield new web3Provider.eth.Contract(
      vaultAbi,
      vaultAddress,
    );

    const allowance = yield* call(lpTokenContract.methods.allowance(address, vaultAddress).call);

    const amountWithDecimals = toDecimals(amount);

    if (new BigNumber(allowance).isLessThan(amountWithDecimals)) {
      yield call(approveSaga, {
        type: userActionTypes.APPROVE,
        payload: {
          web3Provider,
          contract: ContractsNames.lpToken,
          spenderAddress: vaultAddress,
          amount: fromDecimals(MAX_UINT),
        },
      });
    }

    // CHECK IF CAN SEND
    yield* call(vaultContract.methods.depositFor(amountWithDecimals, address).estimateGas, {
      from: address,
    });

    yield* call(vaultContract.methods.depositFor(amountWithDecimals, address).send, {
      from: address,
    });

    yield* call(getVaultsDataSaga, {
      type: actionTypes.GET_VAULTS_DATA,
      payload: undefined,
    });

    yield* put(
      updateUserState({ lpTokenBalance: new BigNumber(lpTokenBalance).minus(amount).toString() }),
    );

    yield* put(apiActions.success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    notify.error('Something may go wrong. Please check your inputs.');
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.DEPOSIT_LP, depositLpSaga);
}
