import { createAction } from '@reduxjs/toolkit';

import {
  ApproveReq,
  getBonusCampaignRewardReq,
  GetFtmBalanceReq,
  GetTokenBalanceReq,
  getUserBoostLevelReq,
  getUserLockupDataReq,
  GetUserMainInfoReq,
  getUserRewardsReq,
  GetUserVaultsDataReq,
  RequestWithWeb3Provider,
} from '@/types/requests';

import actionTypes from './actionTypes';

export const getTokenBalance = createAction<GetTokenBalanceReq>(actionTypes.GET_TOKEN_BALANCE);
export const getVeTokenBalance = createAction<GetTokenBalanceReq>(actionTypes.GET_VE_TOKEN_BALANCE);
export const getFtmBalance = createAction<GetFtmBalanceReq>(actionTypes.GET_FTM_BALANCE);
export const getUserSignatureInfo = createAction(actionTypes.GET_USER_SIGNATURE_INFO);
export const getUserMainInfo = createAction<GetUserMainInfoReq>(actionTypes.GET_USER_MAIN_INFO);
export const getUserLockupData = createAction<getUserLockupDataReq>(
  actionTypes.GET_USER_LOCKUP_DATA,
);
export const getUserBoostLevel = createAction<getUserBoostLevelReq>(
  actionTypes.GET_USER_BOOST_LEVEL,
);
export const getUserHiveRewards = createAction<getUserRewardsReq>(
  actionTypes.GET_USER_HIVE_REWARDS,
);
export const getBonusCampaignReward = createAction<getBonusCampaignRewardReq>(
  actionTypes.GET_BONUS_CAMPAIGN_REWARD,
);
export const getLpTokenBalance = createAction<GetTokenBalanceReq>(actionTypes.GET_LP_TOKEN_BALANCE);
export const getCrvTokenBalance = createAction<GetTokenBalanceReq>(
  actionTypes.GET_CRV_TOKEN_BALANCE,
);
export const getCvxCrvTokenBalance = createAction<GetTokenBalanceReq>(
  actionTypes.GET_CVX_CRV_TOKEN_BALANCE,
);
export const getUserVaultsData = createAction<GetUserVaultsDataReq>(
  actionTypes.GET_USER_VAULTS_DATA,
);
export const getMigrationInfo = createAction<RequestWithWeb3Provider>(
  actionTypes.GET_MIGRATION_INFO,
);
export const claimMigrationTokens = createAction<RequestWithWeb3Provider>(
  actionTypes.CLAIM_MIGRATION_TOKENS,
);
export const signTermsOfUse = createAction<RequestWithWeb3Provider>(actionTypes.SIGN_TERMS_OF_USE);
export const getUserXb3Rewards = createAction<getUserRewardsReq>(actionTypes.GET_USER_XB3_REWARDS);

export const approve = createAction<ApproveReq>(actionTypes.GET_USER_MAIN_INFO);
