import { call, put, select, takeLatest } from 'typed-redux-saga';

import { baseInfoApi } from '@/api';
import { ContractsNames } from '@/config';
import { error, request, success } from '@/store/api/actions';
import userSelector from '@/store/user/selectors';
import { Xbe2Xb3MigratorAbi } from '@/types';
import { notify } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { getMigrationInfo } from '../actions';
import actionTypes from '../actionTypes';
import * as reducer from '../reducer';

export function* getMigrationInfoSaga({
  type,
  payload: { web3Provider },
}: ReturnType<typeof getMigrationInfo>) {
  yield put(request(type));
  const userAddress = yield* select(userSelector.getProp('address'));

  try {
    const [abi, address] = getContractDataByHisName(ContractsNames.xbe2xb3Migrator);
    const migratorContract: Xbe2Xb3MigratorAbi = yield new web3Provider.eth.Contract(abi, address);

    const isUserMigrateTokens = yield* call(migratorContract.methods.migrated(userAddress).call);

    if (isUserMigrateTokens) {
      yield put(reducer.updateUserState({ isUserMigrateTokens }));
      yield put(success(type));
      return;
    }

    const userData = yield* call(baseInfoApi.getSnapshotUserInfo, userAddress);

    if (!userData) {
      notify.error('You are not eligible to claim XB3');
      yield put(success(type));
      return;
    }

    yield put(reducer.updateUserState({ migrationInfo: userData! }));

    yield put(success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_MIGRATION_INFO, getMigrationInfoSaga);
}
