import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HiveState } from '@/types';

const initialState: HiveState = {
  totalClaimable: '0',
  totalDeposits: '0',
  totalCombinedApr: '0',
  vaults: [],
};

export const hiveReducer = createSlice({
  name: 'hive',
  initialState,
  reducers: {
    updateHiveState: (state, action: PayloadAction<Partial<HiveState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateHiveState } = hiveReducer.actions;

export default hiveReducer.reducer;
