import { call, put, takeLatest } from 'typed-redux-saga';

import { ContractsNames } from '@/config';
import { error, request, success } from '@/store/api/actions';
import { BonusCampaignAbi } from '@/types/contracts';
import { fromDecimals } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { getBonusCampaignReward } from '../actions';
import actionTypes from '../actionTypes';
import { updateUserState } from '../reducer';

export function* getBonusCampaignRewardSaga({
  type,
  payload: { web3Provider, address },
}: ReturnType<typeof getBonusCampaignReward>) {
  yield put(request(type));
  const [bonusCampaignAbi, bonusCampaignAddress] = getContractDataByHisName(
    ContractsNames.bonusCampaign,
  );

  try {
    const bonusCampaignContract: BonusCampaignAbi = yield new web3Provider.eth.Contract(
      bonusCampaignAbi,
      bonusCampaignAddress,
    );
    if (address) {
      const balance = yield* call(bonusCampaignContract.methods.rewards(address).call);
      const isUserRegisteredToBonusCampaign = yield* call(
        bonusCampaignContract.methods.registered(address).call,
      );

      yield put(
        updateUserState({
          bonusCampaignReward: fromDecimals(balance),
          isUserRegisteredToBonusCampaign,
        }),
      );
    }

    yield put(success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_BONUS_CAMPAIGN_REWARD, getBonusCampaignRewardSaga);
}
