import api from '@/api/axios';
import { MigrationInfo } from '@/types/store/user';

export const baseInfoApi = {
  getAverageLockTime: () => api.get<{ days: number }>('/lockups/avg_time/'),
  getSnapshotUserInfo: (address: string) =>
    api
      .get<MigrationInfo | null>(`/snapshot/users/${address}/`)
      .then((res) => res.data)
      .catch(() => null),
};
