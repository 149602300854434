import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserState } from '@/types';

const initialMigrationInfo = {
  address: '',
  xbeBalance: '',
  vsrStaked: '',
  vsrBoost: '',
  vsrReward: '',
  bcStaked: '',
  bcReward: '',
  referralReward: '',
  vexbeBalance: '',
  vexbeLockedAmount: '',
  vexbeLockedEnd: 0,
  sushiVaultEarned: '',
  frauxVaultEarned: '',
  crvCvxVaultEarned: '',
  merkleProof: [],
  createdAt: '',
};

const initialState: UserState = {
  address: '',
  xb3Balance: '0',
  veXb3Balance: '0',
  ethBalance: '0',
  lpTokenBalance: '0',
  crvTokenBalance: '0',
  cvxCrvTokenBalance: '0',
  lockup: {
    lockedBalance: '0',
    lockStars: '0',
    lockEnds: '0',
    currentLockAmount: '0',
    pendingReward: '0',
  },
  xb3Rewards: {
    xb3: '0',
    veXb3: '0',
  },
  hiveRewards: {
    crv: '0',
    cvx: '0',
    xb3: '0',
    veXb3: '0',
  },
  bonusCampaignReward: '0',
  isUserRegisteredToBonusCampaign: false,
  boostLevel: 0,
  hiveDeposits: ['0'],
  provider: '',
  migrationInfo: initialMigrationInfo,
  isUserMigrateTokens: false,
  isUserHaveSignature: false,
};

export const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserState: (state, action: PayloadAction<Partial<UserState>>) => ({
      ...state,
      ...action.payload,
    }),
    setMigrationSuccess: (state) => ({
      ...state,
      isUserMigrateTokens: true,
      migrationInfo: initialMigrationInfo,
    }),
    disconnectWalletState: () => {
      localStorage.removeItem('walletconnect');
      return {
        ...initialState,
      };
    },
  },
});

export const { disconnectWalletState, updateUserState, setMigrationSuccess } = userReducer.actions;

export default userReducer.reducer;
