import { call, put, takeLatest } from 'typed-redux-saga';

import { referralsApi } from '@/api/referralsApi';
import apiActions from '@/store/api/actions';

import { hitReferrer } from '../actions';
import actionTypes from '../actionTypes';

export function* hitReferrerSaga({ type, payload: { code } }: ReturnType<typeof hitReferrer>) {
  yield* put(apiActions.request(type));

  try {
    yield* call(referralsApi.hitReferrer, code);

    yield* put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.HIT_REFERRER, hitReferrerSaga);
}
