import { FC } from 'react';
import cn from 'clsx';

import { CrossIcon, WarnIcon } from '@/assets/img/icons';

import { Button } from '../Button';
import { Modal, ModalProps } from '../Modal';
import { Typography } from '../Typography';

import s from './styles.module.scss';

export interface TermOfUseModalProps extends ModalProps {
  onSign: () => void;
  isSignInProcess?: boolean;
  className?: string;
}

export const TermOfUseModal: FC<TermOfUseModalProps> = ({
  className,
  visible,
  isSignInProcess = false,
  onClose,
  onSign,
}) => {
  return (
    <Modal className={cn(s.termOfUseModal, className)} visible={visible} onClose={onClose}>
      <div className={s.header}>
        <Button onClick={onClose} variant="text">
          <CrossIcon />
        </Button>
      </div>
      <div className={s.content}>
        <WarnIcon className={s.warnIcon} />
        <Typography isUpper type="h3" className={s.title}>
          Acknowledgement of Terms & Conditions of access
        </Typography>
        <p className={s.subtitle}>
          Please sign the below message (this is a once off acknowledgement required to use any of
          the XB3 protocol functions) before proceeding. By signing this message you are
          acknowledging the following:
        </p>
        <p className={s.subtitle}>
          Use of the xb3.finance website, services, dapp, or application is subject to the
          associated terms and conditions and I hereby confirm that I am aware of these and accept
          them in full. Over and above the referred to terms and conditions, I specifically confirm
          and accept the following:
        </p>
        <ul className={s.list}>
          <li className={s.listItem}>
            xb3.finance is a smart contract protocol in alpha stage of launch, and even though
            multiple security audits have been completed on the smart contracts, I understand the
            risks associated with using the xb3.finance protocol and associated functions.
          </li>
          <li className={s.listItem}>
            Any interactions that I have with the associated XB3 protocol apps, smart contracts or
            any related functions MAY place my funds at risk, and I hereby release the XB3 protocol
            and its contributors, team members, and service providers from any and all liability
            associated with my use of the abovementioned functions.
          </li>
          <li className={s.listItem}>
            I am lawfully permitted to access this site and use the xb3.finance application
            functions, and I am not in contravention of any laws governing my jurisdiction of
            residence or citizenship.
          </li>
        </ul>
        <Button loading={isSignInProcess} color="darkBlue" onClick={onSign}>
          Sign & Proceed
        </Button>
      </div>
    </Modal>
  );
};
