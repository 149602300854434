import { call, put, takeLatest } from 'typed-redux-saga';

import { ContractsNames } from '@/config';
import { error, request, success } from '@/store/api/actions';
import { VeXb3TokenAbi } from '@/types';
import { fromDecimals } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { getVeTokenBalance } from '../actions';
import actionTypes from '../actionTypes';
import { updateUserState } from '../reducer';

export function* getVeTokenBalanceSaga({
  type,
  payload: { web3Provider, address },
}: ReturnType<typeof getVeTokenBalance>) {
  yield put(request(type));
  const [veXb3Abi, veXb3Address] = getContractDataByHisName(ContractsNames.veXb3Token);

  try {
    const veXb3Contract: VeXb3TokenAbi = yield new web3Provider.eth.Contract(
      veXb3Abi,
      veXb3Address,
    );
    if (address) {
      const balance = yield* call(veXb3Contract.methods['balanceOf(address)'](address).call);
      const decimals = yield* call(veXb3Contract.methods.decimals().call);

      yield put(updateUserState({ veXb3Balance: fromDecimals(balance, +decimals) }));
    }

    yield put(success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_VE_TOKEN_BALANCE, getVeTokenBalanceSaga);
}
