import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ReferralsState } from '@/types';

export const initialStatistic = {
  tiers: {
    '1': {
      members: 0,
      deposits: 0,
    },
    '2': {
      members: 0,
      deposits: 0,
    },
    '3': {
      members: 0,
      deposits: 0,
    },
  },
  total: {
    members: 0,
    deposits: 0,
  },
  rewards: {
    claimable: 0,
    pending: 0,
  },
  createdAt: '',
  pageVisitsCounter: 0,
};

const initialState: ReferralsState = {
  isUserRegistered: false,
  referralNetwork: [],
  statistic: initialStatistic,
  myCode: '',
  shortUrl: '',
};

export const referralsReducer = createSlice({
  name: 'referrals',
  initialState,
  reducers: {
    updateReferralsState: (state, action: PayloadAction<Partial<ReferralsState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateReferralsState } = referralsReducer.actions;

export default referralsReducer.reducer;
