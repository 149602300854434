import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { TermOfUseModal } from '@/components/TermOfUseModal';
import { Header, RouterManager } from '@/containers';
import { useShallowSelector } from '@/hooks';
import { useSmoothTopScroll } from '@/hooks/useSmoothTopScroll';
import { useWalletConnectorContext } from '@/services';
import { getVaultsData } from '@/store/hive/actions';
import hiveActionTypes from '@/store/hive/actionTypes';
import { getAdditionalInfo } from '@/store/info/actions';
import { getLockupData } from '@/store/lockup/actions';
import { getRates } from '@/store/rates/actions';
import ratesActionTypes from '@/store/rates/actionTypes';
import { getReferralStats } from '@/store/referrals/actions';
import referralsSelector from '@/store/referrals/selectors';
import uiSelector from '@/store/ui/selectors';
import { getUserSignatureInfo, getUserVaultsData, signTermsOfUse } from '@/store/user/actions';
import userActionTypes from '@/store/user/actionTypes';
import userSelector from '@/store/user/selectors';
import { RequestStatus } from '@/types';
import { notify } from '@/utils';

import s from './App.module.scss';

const App: FC = () => {
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();
  const { isUserHaveSignature, address: userAddress } = useShallowSelector(userSelector.getUser);
  const isUserRegistered = useShallowSelector(referralsSelector.getProp('isUserRegistered'));
  const { pathname } = useLocation();

  const [isWarnModalOpen, setIsWarnModalOpen] = useState(false);

  const firstPathAtPathname = useMemo(() => pathname.split('/')[1], [pathname]);
  useSmoothTopScroll(firstPathAtPathname);
  const {
    [ratesActionTypes.GET_RATES]: getRatesRequestStatus,
    [hiveActionTypes.GET_VAULTS_DATA]: getVaultsDataRequestStatus,
    [userActionTypes.GET_USER_SIGNATURE_INFO]: getUserSignatureInfoRequestStatus,
    [userActionTypes.SIGN_TERMS_OF_USE]: signTermsOfUseRequestStatus,
  } = useShallowSelector(uiSelector.getUI);
  /*
    ⠄⠄⠄⠄⠄⠄⢀⣠⡶⠖⠛⠉⠉⠉⠉⠉⠛⠲⣦⣄⠄⠄⠄⠄
    ⠄⠄⠄⠄⣤⠖⠋⠁⠄⠄⠄⠄⢀⣴⣿⠛⠙⠛⢷⣤⣈⢿⠄⠄
    ⠄⠄⣴⠋⠄⠄⠄⠄⣀⣤⣶⠶⠚⠛⠁⠄⠄⠄⠄⠄⠄⠄⣿⠄
    ⢀⡟⣠⣶⠖⠛⠉⢁⣠⣴⣶⢶⡄⠄⠺⣯⣭⣭⣭⣿⠿⠗⢸⡆
    ⣾⠄⠄⠄⣴⣞⣉⣈⣿⡿⠛⠁⠄⠄⠄⠄⣻⣦⠶⠛⠉⠙⢿⡇
    ⣿⠄⠄⠄⠄⠄⠄⠄⠄⠄⢀⣠⣤⠶⠛⠉⠄⠄⠄⠄⠄⡶⢻⠁
    ⣿⠄⠄⠄⠄⠄⠛⠛⠛⠉⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⢰⡇⣿⠄
    ⠘⣆⠄⠄⠄⠄⠄⠄⠄⢀⠄⠄⠄⠄⠄⠄⠄⠄⠄⢠⡟⣼⠃⠄
    ⠄⠹⣄⠄⠄⠄⠄⠄⠄⠄⠛⣦⣀⠄⠄⠄⠄⣠⡶⠋⣼⠃⠄⠄
    ⠄⠄⠈⠛⣦⡀⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⣠⡾⠋⠄⠄⠄⠄
    ⠄⠄⠄⠄⠄⠈⠉⠛⠛⠶⣤⣿⣿⣴⣶⠛⠉⠄⠄⠄⠄⠄⠄⠄
    ⠄⠄⠄⠄⠄⠄⠄⠄⠄⣰⠋⢸⠄⠙⢷⡀⠄⠄⠄⠄⠄⠄⠄⠄
    ⠄⠄⠄⠄⠄⠄⠄⠄⣾⠁⠄⢸⠄⠄⠄⠈⢷⡀⠄⠄⠄⠄⠄⠄
    ⠄⠄⠄⠄⠄⠄⢠⡟⠄⠄⠄⢸⡆⠄⠄⠄⠄⠘⢶⡀⠄⠄⠄⠄
    ⠄⠄⠄⠄⠄⣾⠃⠄⠄⠄⠄⠄⣇⠄⠄⠄⠄⠄⠄⠻⡄⠄⠄⠄
    ⠄⠄⠄⢀⡿⠄⠄⠄⠄⠄⠄⣀⣿⣀⣀⣀⣀⣀⣀⡀⢹⣦⣤⠄
    ⢀⣤⣶⣿⣿⣷⣶⠟⠛⠉⠄⠄⢸⡄⠄⠄⠉⠙⠛⠿⣿⣿⣦⢻
    ⠄⣸⠃⢿⠄⠄⠄⠄⠄⠄⠄⠄⠄⡇⠄⠄⠄⠄⠄⠄⠘⣿⠄⠄
  */

  const handleCloseTermsOfUseModal = () => {
    if (isUserHaveSignature) {
      setIsWarnModalOpen(false);
    } else {
      notify.info('Please sign the terms of use!');
    }
  };

  const handleSignTermsOfUse = () => {
    dispatch(signTermsOfUse({ web3Provider: walletService.Web3() }));
  };

  useEffect(() => {
    dispatch(getRates());
    dispatch(getAdditionalInfo());
    dispatch(getLockupData());
  }, [dispatch]);

  useEffect(() => {
    if (getRatesRequestStatus === RequestStatus.SUCCESS) {
      dispatch(getVaultsData());
    }
  }, [dispatch, getRatesRequestStatus]);

  useEffect(() => {
    if (userAddress && getVaultsDataRequestStatus === RequestStatus.SUCCESS) {
      dispatch(getUserVaultsData({ web3Provider: walletService.Web3(), userAddress }));
    }
  }, [userAddress, dispatch, getVaultsDataRequestStatus, walletService]);

  useEffect(() => {
    if (userAddress && isUserRegistered) {
      dispatch(getReferralStats());
    }
  }, [dispatch, userAddress, isUserRegistered]);

  useEffect(() => {
    if (userAddress) {
      dispatch(getUserSignatureInfo());
    }
  }, [dispatch, userAddress]);

  useEffect(() => {
    if (getUserSignatureInfoRequestStatus === RequestStatus.SUCCESS) {
      setIsWarnModalOpen(!isUserHaveSignature);
    }
  }, [getUserSignatureInfoRequestStatus, isUserHaveSignature, userAddress]);

  return (
    <div className={s.main_wrapper}>
      <TermOfUseModal
        isSignInProcess={signTermsOfUseRequestStatus === RequestStatus.REQUEST}
        onSign={handleSignTermsOfUse}
        visible={isWarnModalOpen}
        onClose={handleCloseTermsOfUseModal}
      />
      <Header />
      <div className={s.page_wrapper}>
        <RouterManager />
      </div>
    </div>
  );
};
export default App;
