import { call, put, select, takeLatest } from 'typed-redux-saga';

import { error, request, success } from '@/store/api/actions';
import userSelector from '@/store/user/selectors';

import { getUserMainInfo } from '../actions';
import actionTypes from '../actionTypes';

import { getBonusCampaignRewardSaga } from './getBonusCampaignReward';
import { getCrvTokenBalanceSaga } from './getCrvTokenBalance';
import { getCvxCrvTokenBalanceSaga } from './getCvxCrvTokenBalance';
import { getFtmBalanceSaga } from './getEthBalance';
import { getLpTokenBalanceSaga } from './getLpTokenBalance';
import { getTokenBalanceSaga } from './getTokenBalance';
import { getUserBoostLevelSaga } from './getUserBoostLevel';
import { getUserHiveRewardsSaga } from './getUserHiveRewards';
import { getUserLockupDataSaga } from './getUserLockupData';
import { getUserXb3RewardsSaga } from './getUserXb3Rewards';
import { getVeTokenBalanceSaga } from './getVeTokenBalance';

export function* getUserMainInfoSaga({
  type,
  payload: { web3Provider },
}: ReturnType<typeof getUserMainInfo>) {
  yield put(request(type));
  const userAddress = yield* select(userSelector.getProp('address'));

  try {
    yield call(getFtmBalanceSaga, {
      type: actionTypes.GET_FTM_BALANCE,
      payload: { web3Provider },
    });
    yield call(getTokenBalanceSaga, {
      type: actionTypes.GET_TOKEN_BALANCE,
      payload: { web3Provider, address: userAddress },
    });
    yield call(getVeTokenBalanceSaga, {
      type: actionTypes.GET_TOKEN_BALANCE,
      payload: { web3Provider, address: userAddress },
    });
    yield call(getUserLockupDataSaga, {
      type: actionTypes.GET_USER_LOCKUP_DATA,
      payload: { web3Provider, address: userAddress },
    });
    yield call(getUserHiveRewardsSaga, {
      type: actionTypes.GET_USER_HIVE_REWARDS,
      payload: { web3Provider, address: userAddress },
    });
    yield call(getUserXb3RewardsSaga, {
      type: actionTypes.GET_USER_XB3_REWARDS,
      payload: { web3Provider, address: userAddress },
    });
    yield call(getBonusCampaignRewardSaga, {
      type: actionTypes.GET_BONUS_CAMPAIGN_REWARD,
      payload: { web3Provider, address: userAddress },
    });
    yield call(getLpTokenBalanceSaga, {
      type: actionTypes.GET_LP_TOKEN_BALANCE,
      payload: { web3Provider, address: userAddress },
    });
    yield call(getCrvTokenBalanceSaga, {
      type: actionTypes.GET_CRV_TOKEN_BALANCE,
      payload: { web3Provider, address: userAddress },
    });
    yield call(getCvxCrvTokenBalanceSaga, {
      type: actionTypes.GET_CVX_CRV_TOKEN_BALANCE,
      payload: { web3Provider, address: userAddress },
    });
    yield call(getUserBoostLevelSaga, {
      type: actionTypes.GET_USER_BOOST_LEVEL,
      payload: {
        web3Provider,
        address: userAddress,
      },
    });
    yield put(success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_USER_MAIN_INFO, getUserMainInfoSaga);
}
