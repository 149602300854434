/* PLOP_INJECT_IMPORT_REDUCER */
import hive from './hive/reducer';
import info from './info/reducer';
import lockup from './lockup/reducer';
import rates from './rates/reducer';
import referrals from './referrals/reducer';
import rewards from './rewards/reducer';
import ui from './ui/reducer';
import user from './user/reducer';

export default {
  ui,
  user,
  /* PLOP_INJECT_PLACE_REDUCER */
  hive,
  referrals,
  info,
  rewards,
  lockup,
  rates,
};
