import BigNumber from 'bignumber.js';
import { all, call, put, select, takeLatest } from 'typed-redux-saga';

import { hiveApi } from '@/api/hiveApi';
import { ContractsNames } from '@/config';
import apiActions from '@/store/api/actions';
import { updateHiveState } from '@/store/hive/reducer';
import ratesSelector from '@/store/rates/selectors';
import { HiveVault } from '@/types';
import { createContract, fromDecimals } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { getVaultsData } from '../actions';
import actionTypes from '../actionTypes';

export function* getVaultsDataSaga({ type }: ReturnType<typeof getVaultsData>) {
  yield* put(apiActions.request(type));

  try {
    const [, xb3Address] = getContractDataByHisName(ContractsNames.xb3Token);
    const [vaultAbi] = getContractDataByHisName(ContractsNames.convexVault);
    const rates = yield* select(ratesSelector.getRates);
    const vaults = yield* call(hiveApi.getVaults);
    const contractsPromises: any = vaults.map(({ address }) => createContract(vaultAbi, address));
    const contracts: any = yield* all(contractsPromises); // TODO change any

    const totalStakedVaultsPromises = vaults.map((_, index) =>
      contracts[index].methods.totalSupply().call(),
    );
    const crvRewardAddressesPromises = vaults.map((_, index) =>
      contracts[index].methods.crvRewardAddress().call(),
    );
    const stakeTokenAddressesPromises = vaults.map((_, index) =>
      contracts[index].methods.stakeToken().call(),
    );

    const totalStakedVaults: any = yield* all(totalStakedVaultsPromises); // TODO change any
    const crvRewardAddresses: any = yield* all(crvRewardAddressesPromises);
    const stakeTokenAddresses: any = yield* all(stakeTokenAddressesPromises);

    const formattedVaults: HiveVault[] = vaults.map((vault, index) => {
      const lpTokenData: any = Object.values(rates).find((rate) => rate.symbol === vault.lpToken);
      return {
        id: index,
        title: vault.name,
        slug: vault.slug,
        address: vault.address,
        apr: vault.annualPercentageRate.toString(),
        claimable: vault.earned.toString(),
        tvl: new BigNumber(fromDecimals(totalStakedVaults[index]))
          .multipliedBy(lpTokenData.price)
          .toString(),
        rewardCrvTokenAddress: crvRewardAddresses[index],
        rewardXb3TokenAddress: xb3Address,
        lpTokenAddress: stakeTokenAddresses[index],
      };
    });

    const totalDeposits = totalStakedVaults.reduce(
      (prevValue: string | number, currentValue: string | number) =>
        new BigNumber(prevValue).plus(currentValue).toString(),
      0,
    );

    const totalClaimable = new BigNumber(
      formattedVaults.reduce(
        (sum, { claimable }) => new BigNumber(sum).plus(claimable).toNumber(),
        0,
      ),
    )
      .dividedBy(formattedVaults.length)
      .toString();

    const totalCombinedApr = new BigNumber(
      formattedVaults.reduce((sum, { apr }) => new BigNumber(sum).plus(apr).toNumber(), 0),
    )
      .dividedBy(formattedVaults.length)
      .toString();

    yield put(
      updateHiveState({
        vaults: formattedVaults,
        totalDeposits: fromDecimals(totalDeposits),
        totalClaimable,
        totalCombinedApr,
      }),
    );

    yield* put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_VAULTS_DATA, getVaultsDataSaga);
}
