import { FC } from 'react';
import BigNumber from 'bignumber.js';
import cn from 'clsx';

import { AdditionalInfoIcon } from '@/assets/img/icons';
import { Expander, Typography } from '@/components';
import { XB3_DOCS_URL } from '@/config/constants';
import { useShallowSelector } from '@/hooks';
import infoSelector from '@/store/info/selectors';
import ratesSelector from '@/store/rates/selectors';
import userSelector from '@/store/user/selectors';

import s from './styles.module.scss';

export interface AdditionalInformationProps {
  className?: string;
}

export const AdditionalInformation: FC<AdditionalInformationProps> = ({ className }) => {
  const {
    currentCirculatingSupply,
    totalLockedXb3,
    totalLockedXb3Percent,
    maxSupply,
    averageLockTime,
  } = useShallowSelector(infoSelector.getInfo);
  const { price: xb3PriceInUsd } = useShallowSelector(ratesSelector.getProp('xb3'));
  const veXb3Balance = useShallowSelector(userSelector.getProp('veXb3Balance'));

  return (
    <Expander
      className={cn(s.additionalInformation, className)}
      contentClassName={s.headerContent}
      header={
        <div className={cn(s.content)}>
          <AdditionalInfoIcon className={cn(s.icon)} />
          <div>
            <Typography type="h4" weight="medium" isUpper>
              Additional Information
            </Typography>
            <Typography spacing={0} className={s.subtitle} weight="medium" type="label2">
              For more information on the XB3 Finance please visit&nbsp;
              <a target="_blank" rel="noreferrer noopener" href={XB3_DOCS_URL}>
                docs.xb3.finance
              </a>
            </Typography>
          </div>
        </div>
      }
    >
      <div className={s.body}>
        <ul className={s.info}>
          <li className={s.row}>
            <Typography type="body1" color="gray" weight="medium">
              Current Circulating Supply
            </Typography>
            <Typography type="body1" weight="bold">
              {new BigNumber(currentCirculatingSupply).toFixed(2)} XB3
            </Typography>
          </li>
          <li className={s.row}>
            <Typography type="body1" color="gray" weight="medium">
              Total Locked in XB3
            </Typography>
            <Typography type="body1" weight="bold">
              {new BigNumber(totalLockedXb3).toFixed(2)} XB3
            </Typography>
          </li>
          <li className={s.row}>
            <Typography type="body1" color="gray" weight="medium">
              % of Total Locked XB3
            </Typography>
            <Typography type="body1" weight="bold">
              {new BigNumber(totalLockedXb3Percent).toFixed(2)}%
            </Typography>
          </li>
          <li className={s.row}>
            <Typography type="body1" color="gray" weight="medium">
              Max Supply
            </Typography>
            <Typography type="body1" weight="bold">
              {new BigNumber(maxSupply).toFixed(2)} XB3
            </Typography>
          </li>
          <li className={s.row}>
            <Typography type="body1" color="gray" weight="medium">
              Total Value Locked In USD
            </Typography>
            <Typography type="body1" weight="bold">
              $ {new BigNumber(totalLockedXb3).multipliedBy(xb3PriceInUsd).toFixed(2)}
            </Typography>
          </li>
          <li className={s.row}>
            <Typography type="body1" color="gray" weight="medium">
              Total veXB3
            </Typography>
            <Typography type="body1" weight="bold">
              {new BigNumber(totalLockedXb3).toFixed(2)}
            </Typography>
          </li>
          <li className={s.row}>
            <Typography type="body1" color="gray" weight="medium">
              Average Lock Time
            </Typography>
            <Typography type="body1" weight="bold">
              {averageLockTime} Days
            </Typography>
          </li>
        </ul>
        <div className={s.subinfo}>
          <Typography color="gray" weight="medium" type="body1">
            Your Starting Voting Power Will Be
          </Typography>
          <Typography type="body1" weight="bold">
            {new BigNumber(veXb3Balance).toFixed(2)} veXB3
          </Typography>
        </div>
      </div>
    </Expander>
  );
};
