import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import BigNumber from 'bignumber.js';
import cn from 'clsx';

import { Xb3Logo } from '@/assets/img/icons';
import {
  Button,
  DetailedInfoPopover,
  Expander,
  InfoPopover,
  LabelSection,
  Typography,
} from '@/components';
import { useShallowSelector } from '@/hooks';
import { claimableTokens } from '@/pages/Xb3/components/Rewards/Rewards.helper';
import { useWalletConnectorContext } from '@/services';
import ratesSelector from '@/store/rates/selectors';
import { claimAllRewards } from '@/store/rewards/actions';
import rewardsActionTypes from '@/store/rewards/actionTypes';
import uiSelector from '@/store/ui/selectors';
import userSelector from '@/store/user/selectors';
import { RatesState, RequestStatus, State } from '@/types';

import s from './styles.module.scss';

export interface RewardsProps {
  className?: string;
}

export const Rewards: FC<RewardsProps> = ({ className }) => {
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();

  const {
    lockup: { lockEnds, lockedBalance },
  } = useShallowSelector(userSelector.getUser);
  const userRewards = useShallowSelector(userSelector.getProp('xb3Rewards'));
  const rates = useShallowSelector<State, RatesState>(ratesSelector.getRates);
  const { [rewardsActionTypes.CLAIM_ALL_REWARDS]: claimAllRewardsRequestStatus } =
    useShallowSelector(uiSelector.getUI);

  const rewardEarnedInUsd = useMemo(
    () => new BigNumber(new BigNumber(userRewards.xb3).multipliedBy(rates.xb3.price)).toFixed(2),
    [rates.xb3.price, userRewards],
  );

  const currentTime = Math.floor(Date.now() / 1000);
  const isUserFirstShouldClaimLockup = currentTime > +lockEnds && +lockedBalance > 0;

  // === buttons state ===
  const isClaimAllRewardsButtonDisabled = useMemo(
    () => new BigNumber(userRewards.xb3).plus(userRewards.veXb3).isEqualTo(0),
    // || isUserFirstShouldClaimLockup,
    [userRewards],
  );

  // === request statuses ===
  const isClaimAllRewardsInProcess = useMemo(
    () => claimAllRewardsRequestStatus === RequestStatus.REQUEST,
    [claimAllRewardsRequestStatus],
  );

  // === handlers ===
  const handleClaimAllRewards = () => {
    dispatch(claimAllRewards({ web3Provider: walletService.Web3(), type: 'lockup' }));
  };

  return (
    <section className={cn(s.rewards, className)}>
      <LabelSection
        icon={<Xb3Logo />}
        title="Xb3 rewards"
        background="green"
        endContent={<DetailedInfoPopover />}
      />
      {/* ==== STAKING REWARDS ==== */}
      <Expander
        background="green"
        className={s.expander}
        header={
          <div>
            <div className={s.title}>
              <Xb3Logo className={s.oneLogo} width="30px" />
              <Typography weight="medium" type="body1">
                XB3 Rewards
              </Typography>
            </div>
            <div className={s.values}>
              <div>
                <Typography className={s.label} type="label1">
                  Earned (USD Value)
                </Typography>
                <Typography type="h2">${rewardEarnedInUsd}</Typography>
              </div>
            </div>
            <Button
              disabled={isClaimAllRewardsButtonDisabled}
              loading={isClaimAllRewardsInProcess}
              onClick={handleClaimAllRewards}
              color="green"
              endIcon={
                isUserFirstShouldClaimLockup ? (
                  <InfoPopover>First pick up your lock on the Xb3 Lockup page</InfoPopover>
                ) : undefined
              }
            >
              Claim All Staking Rewards
            </Button>
          </div>
        }
      >
        <div className={s.claim_table_header}>
          <div className={s.header_item}>
            <Typography type="label2" isUpper>
              Asset
            </Typography>
          </div>
          <div className={s.header_item}>
            <Typography type="label2" isUpper>
              Amount
            </Typography>
          </div>
          <div className={s.header_item}>
            <Typography type="label2" isUpper>
              Value
            </Typography>
          </div>
        </div>
        {claimableTokens.map(({ icon: Icon, asset, grayOut }) => {
          return (
            <div
              key={asset}
              className={cn(s.claim_table_content, grayOut && s.claim_table_content__grayOut)}
            >
              <div className={s.logo_title_wrapper}>
                <Icon style={{ width: 40, height: 40 }} />
                <Typography type="body1">{asset.toUpperCase()} Earned</Typography>
              </div>
              <Typography type="h4" weight="medium">
                {new BigNumber(userRewards[asset]).isNaN()
                  ? '0'
                  : new BigNumber(userRewards[asset]).toFixed(2)}
              </Typography>
              {asset !== 'veXb3' ? (
                <Typography type="h4" weight="medium">
                  $
                  {new BigNumber(userRewards[asset]).multipliedBy(rates[asset].price).isNaN()
                    ? '0'
                    : new BigNumber(userRewards[asset]).multipliedBy(rates[asset].price).toFixed(2)}
                </Typography>
              ) : (
                <div />
              )}
            </div>
          );
        })}
        <Typography type="h5" align="center" color="green" weight="bold">
          MORE TO COME IN THE FUTURE!
        </Typography>
      </Expander>
    </section>
  );
};
