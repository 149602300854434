import { all, call, put, select, takeLatest } from 'typed-redux-saga';

import { ContractsNames } from '@/config';
import apiActions from '@/store/api/actions';
import { getLockupDataSaga } from '@/store/lockup/sagas/getLockupDataSaga';
import userActionTypes from '@/store/user/actionTypes';
import { getTokenBalanceSaga } from '@/store/user/sagas/getTokenBalance';
import { getUserLockupDataSaga } from '@/store/user/sagas/getUserLockupData';
import userSelector from '@/store/user/selectors';
import { VeXb3TokenAbi } from '@/types';
import { notify } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { lock } from '../actions';
import actionTypes from '../actionTypes';

export function* unlockSaga({ type, payload: { web3Provider } }: ReturnType<typeof lock>) {
  yield* put(apiActions.request(type));

  try {
    const [veXb3TokenAbi, veXb3TokenAddress] = getContractDataByHisName(ContractsNames.veXb3Token);
    const veXbfContract: VeXb3TokenAbi = yield new web3Provider.eth.Contract(
      veXb3TokenAbi,
      veXb3TokenAddress,
    );

    const userAddress = yield* select(userSelector.getProp('address'));

    // CHECK IF CAN SEND
    yield* call(veXbfContract.methods.withdraw().estimateGas, {
      from: userAddress,
    });

    yield* call(veXbfContract.methods.withdraw().send, {
      from: userAddress,
    });

    const requests = [
      getLockupDataSaga({
        type: actionTypes.GET_LOCKUP_DATA,
        payload: undefined,
      }),
      getUserLockupDataSaga({
        type: userActionTypes.GET_USER_LOCKUP_DATA,
        payload: { web3Provider, address: userAddress },
      }),
      getTokenBalanceSaga({
        type: userActionTypes.GET_TOKEN_BALANCE,
        payload: { web3Provider, address: userAddress },
      }),
    ];

    yield* all(requests);

    yield* put(apiActions.success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    notify.error('Something may go wrong. Please check your inputs.');
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.UNLOCK, unlockSaga);
}
