import { createAction } from '@reduxjs/toolkit';

import { ClaimAllRewardsReq, RequestWithWeb3Provider } from '@/types/requests';

import actionTypes from './actionTypes';

export const claimAllRewards = createAction<ClaimAllRewardsReq>(actionTypes.CLAIM_ALL_REWARDS);
export const claimReferralRewards = createAction<RequestWithWeb3Provider>(
  actionTypes.CLAIM_REFERRAL_REWARDS,
);
