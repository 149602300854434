import { createAction } from '@reduxjs/toolkit';

import {
  IncreaseLockupAmountReq,
  IncreaseUnlockTimeReq,
  LockReq,
  UnlockReq,
} from '@/types/requests';

import actionTypes from './actionTypes';

export const getLockupData = createAction(actionTypes.GET_LOCKUP_DATA);
export const lock = createAction<LockReq>(actionTypes.LOCK);
export const unlock = createAction<UnlockReq>(actionTypes.UNLOCK);
export const increaseLockupAmount = createAction<IncreaseLockupAmountReq>(
  actionTypes.INCREASE_LOCKUP_AMOUNT,
);
export const increaseUnlockTime = createAction<IncreaseUnlockTimeReq>(
  actionTypes.INCREASE_UNLOCK_TIME,
);
