import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import BigNumber from 'bignumber.js';

import { Xb3Logo } from '@/assets/img/icons';
import {
  Button,
  DetailedInfoPopover,
  Input,
  LabelSection,
  ProgressBar,
  RangeSlider,
  Typography,
} from '@/components';
import { SECONDS_IN_WEEK } from '@/config/constants';
import { useShallowSelector } from '@/hooks';
import { useWalletConnectorContext } from '@/services';
import { increaseLockupAmount, increaseUnlockTime } from '@/store/lockup/actions';
import lockupActionTypes from '@/store/lockup/actionTypes';
import uiSelector from '@/store/ui/selectors';
import userSelector from '@/store/user/selectors';
import { RequestStatus, RoundingModes } from '@/types';
import { formatNumber } from '@/utils/numberFormatter';

import s from './styles.module.scss';

export const Boost: FC = () => {
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();

  const { xb3Balance, boostLevel, isUserRegisteredToBonusCampaign } = useShallowSelector(
    userSelector.getUser,
  );
  const { lockedBalance, lockStars, lockEnds } = useShallowSelector(userSelector.getProp('lockup'));
  const {
    [lockupActionTypes.INCREASE_LOCKUP_AMOUNT]: increaseLockupAmountRequest,
    [lockupActionTypes.INCREASE_UNLOCK_TIME]: increaseUnlockTimeRequest,
  } = useShallowSelector(uiSelector.getUI);

  const [lockup, setLockup] = useState('');
  const [weeksAmount, setWeeksAmount] = useState(48);

  const handleIncreaseLockupAmount = () => {
    dispatch(increaseLockupAmount({ web3Provider: walletService.Web3(), amount: lockup }));
  };
  const handleIncreaseUnlockTime = () => {
    dispatch(increaseUnlockTime({ web3Provider: walletService.Web3(), weeks: weeksAmount }));
  };

  const isIncreaseAmountLoading = useMemo(
    () => increaseLockupAmountRequest === RequestStatus.REQUEST,
    [increaseLockupAmountRequest],
  );

  const isIncreaseUnlockTimeLoading = useMemo(
    () => increaseUnlockTimeRequest === RequestStatus.REQUEST,
    [increaseUnlockTimeRequest],
  );

  const userLockInWeeks = useMemo(
    () =>
      new BigNumber(lockEnds)
        .minus(lockStars)
        .dividedBy(SECONDS_IN_WEEK)
        .toFixed(0, RoundingModes.up),
    [lockEnds, lockStars],
  );

  const availableWeeksToAddToBoost = useMemo(
    () => new BigNumber(100).minus(userLockInWeeks).toString(),
    [userLockInWeeks],
  );

  const isAddToBoostButtonDisabled = useMemo(
    () =>
      new BigNumber(lockedBalance).isEqualTo(0) ||
      isUserRegisteredToBonusCampaign ||
      new BigNumber(weeksAmount).isGreaterThan(availableWeeksToAddToBoost),
    [isUserRegisteredToBonusCampaign, lockedBalance, weeksAmount, availableWeeksToAddToBoost],
  );

  const isAddToLockupButtonDisabled = useMemo(
    () =>
      new BigNumber(lockup).isGreaterThan(xb3Balance) ||
      new BigNumber(lockedBalance).isEqualTo(0) ||
      new BigNumber(xb3Balance).isEqualTo(0),
    [xb3Balance, lockup, lockedBalance],
  );

  const availableToAddToBoost = useMemo(() => new BigNumber(xb3Balance).toFixed(), [xb3Balance]);

  return (
    <div className={s.lockup_wrapper}>
      <LabelSection
        icon={<Xb3Logo />}
        title="XB3 Boost"
        background="blue"
        endContent={<DetailedInfoPopover />}
      />
      <div className={s.boost_stats}>
        <div className={s.stats_item}>
          <Typography className={s.label} color="label2" type="label1">
            XB3 Balance
          </Typography>
          <Typography type="h2">{formatNumber(+xb3Balance)}</Typography>
        </div>
        <div className={s.stats_item}>
          <Typography className={s.label} color="label2" type="label1">
            My XB3 Locked
          </Typography>
          <Typography type="h2">{formatNumber(+lockedBalance)}</Typography>
        </div>
      </div>
      <ProgressBar
        className={s.progress}
        title="Your Current Boost"
        value={+new BigNumber(boostLevel).toFixed(2)}
      />
      <LabelSection
        icon={<Xb3Logo />}
        title="ADD to Your Lockup & Extend your Boost"
        background="blue"
        className={s.secondLabel}
        titleClassName={s.labelTitle}
        endContent={<DetailedInfoPopover />}
      />
      <RangeSlider
        title="Lock for period of (Weeks)"
        subtitle={`You can add up to ${availableWeeksToAddToBoost} weeks to the boost`}
        min={0}
        max={25}
        step={4}
        defaultValue={isUserRegisteredToBonusCampaign ? 25 : weeksAmount / 4}
        onChange={(value) => setWeeksAmount(value)}
        color="blue"
        className={s.range}
        disabled={isUserRegisteredToBonusCampaign}
      />
      <Button
        loading={isIncreaseUnlockTimeLoading}
        onClick={handleIncreaseUnlockTime}
        disabled={isAddToBoostButtonDisabled}
        color="blue"
        className={s.btn}
      >
        Add to Boost
      </Button>
      <Input
        className={s.input}
        onChange={setLockup}
        value={lockup}
        label="Lockup"
        onlyNumbers
        placeholder="0.00"
        labelEnd={
          <Typography color="default" className={s.balance} type="body2">
            Available: <span>{availableToAddToBoost}</span>
          </Typography>
        }
        color="gray"
        startIcon={<Xb3Logo />}
      />
      <Button
        loading={isIncreaseAmountLoading}
        onClick={handleIncreaseLockupAmount}
        color="blue"
        disabled={isAddToLockupButtonDisabled}
        className={s.btn}
      >
        Add to Lockup
      </Button>
    </div>
  );
};
