import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RatesState } from '@/types';

const initialState: RatesState = {
  xb3: {
    symbol: '',
    price: 0,
    change24H: 0,
  },
  eth: {
    symbol: '',
    price: 0,
    change24H: 0,
  },
  crv: {
    symbol: '',
    price: 0,
    change24H: 0,
  },
  cvx: {
    symbol: '',
    price: 0,
    change24H: 0,
  },
  cvxCrv: {
    symbol: '',
    price: 0,
    change24H: 0,
  },
  crvCvxCrvLp: {
    symbol: '',
    price: 0,
    change24H: 0,
  },
};

export const ratesReducer = createSlice({
  name: 'rates',
  initialState,
  reducers: {
    updateRatesState: (state, action: PayloadAction<Partial<RatesState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateRatesState } = ratesReducer.actions;

export default ratesReducer.reducer;
