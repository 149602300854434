/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import BigNumber from 'bignumber.js';

import { CrvLogo, CrvLogoSm, CvxIcon, Xb3Logo } from '@/assets/img/icons';
import { Button, DetailedInfoPopover, InfoPopover, LabelSection, Typography } from '@/components';
import { useShallowSelector } from '@/hooks';
import { useWalletConnectorContext } from '@/services';
import ratesSelector from '@/store/rates/selectors';
import { claimAllRewards } from '@/store/rewards/actions';
import rewardsActionTypes from '@/store/rewards/actionTypes';
import uiSelector from '@/store/ui/selectors';
import userSelector from '@/store/user/selectors';
import { RatesState, RequestStatus } from '@/types';

import s from './styles.module.scss';

const rewardsCoins = [
  {
    icon: CrvLogoSm,
    asset: 'crv',
    label: 'CRV',
  },
  {
    icon: CvxIcon,
    asset: 'cvx',
    label: 'CVX',
  },
  {
    icon: Xb3Logo,
    asset: 'xb3',
    label: 'XB3',
  },
  {
    icon: Xb3Logo,
    asset: 'veXb3',
    label: 'veXB3',
  },
];

export const Claim: FC = () => {
  const {
    hiveRewards: userRewards,
    lockup: { lockEnds, lockedBalance },
  } = useShallowSelector(userSelector.getUser);
  const rates = useShallowSelector(ratesSelector.getRates);
  const { [rewardsActionTypes.CLAIM_ALL_REWARDS]: claimAllRewardsRequestStatus } =
    useShallowSelector(uiSelector.getUI);

  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();

  const handleClaimAllRewards = () => {
    dispatch(claimAllRewards({ web3Provider: walletService.Web3(), type: 'hive' }));
  };

  const isClaimAllRewardsInProcess = useMemo(
    () => claimAllRewardsRequestStatus === RequestStatus.REQUEST,
    [claimAllRewardsRequestStatus],
  );

  const rewardEarnedInUsd = useMemo(
    () =>
      new BigNumber(new BigNumber(userRewards.xb3).multipliedBy(rates.xb3.price))
        .plus(new BigNumber(userRewards.crv).multipliedBy(rates.crv.price))
        .plus(new BigNumber(userRewards.cvx).multipliedBy(rates.cvx.price))
        .toFixed(2),
    [rates.crv.price, rates.cvx.price, rates.xb3.price, userRewards],
  );

  const currentTime = Math.floor(Date.now() / 1000);
  const isUserFirstShouldClaimLockup = currentTime > +lockEnds && +lockedBalance > 0;

  // === buttons state ===
  const isClaimAllRewardsButtonDisabled = useMemo(
    () =>
      new BigNumber(userRewards.xb3)
        .plus(userRewards.crv)
        .plus(userRewards.cvx)
        .plus(userRewards.veXb3)
        .isEqualTo(0) || isUserFirstShouldClaimLockup,
    [userRewards, isUserFirstShouldClaimLockup],
  );

  return (
    <div className={s.claim_wrapper}>
      <LabelSection
        icon={<CrvLogo />}
        background="dark"
        title="Claim earnings"
        endContent={<DetailedInfoPopover />}
      />
      <div className={s.claims}>
        <div className={s.scrollableContainer}>
          <div className={s.wrapper}>
            <div className={s.claim_table_header}>
              <div className={s.header_item}>
                <Typography type="label2" isUpper>
                  Asset
                </Typography>
              </div>
              <div className={s.header_item}>
                <Typography type="label2" isUpper>
                  Amount
                </Typography>
              </div>
              <div className={s.header_item}>
                <Typography type="label2" isUpper>
                  Value
                </Typography>
              </div>
            </div>
            {rewardsCoins.map((coin) => {
              const Icon = coin.icon;
              return (
                <div key={coin.asset} className={s.claim_table_content}>
                  <div className={s.logo_title_wrapper}>
                    <Icon style={{ width: 40, height: 40 }} />
                    <Typography type="body1">{coin.label} Earned</Typography>
                  </div>
                  <Typography type="h4" weight="medium">
                    {/* @ts-expect-error */}
                    {new BigNumber(userRewards[coin.asset]).toFixed(2)}
                  </Typography>
                  {coin.asset in rates && (
                    <Typography type="h4" weight="medium">
                      ${/* @ts-expect-error */}
                      {new BigNumber(userRewards[coin.asset])
                        .multipliedBy(rates[coin.asset as keyof RatesState].price)
                        .toFixed(2)}
                    </Typography>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <Typography
          className={s.moreTitle}
          align="center"
          weight="bold"
          type="h5"
          isUpper
          color="blue"
        >
          More to come in the future
        </Typography>
        <div className={s.total_wrapper}>
          <Typography type="body1" height={23} weight="bold">
            Total Earned
          </Typography>
          <Typography type="h4" height={23} weight="bold" spacing={0} className={s.total_earned}>
            ${rewardEarnedInUsd}
          </Typography>
        </div>
        <Button
          disabled={isClaimAllRewardsButtonDisabled}
          loading={isClaimAllRewardsInProcess}
          onClick={handleClaimAllRewards}
          color="darkBlue"
          endIcon={
            isUserFirstShouldClaimLockup ? (
              <InfoPopover>First pick up your lock on the Xb3 Lockup page</InfoPopover>
            ) : undefined
          }
        >
          Claim All Staking Rewards
        </Button>
      </div>
    </div>
  );
};
