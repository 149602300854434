import { FC } from 'react';

import { CopyIcon } from '@/assets/img/icons';
import { Button, Typography } from '@/components';
import s from '@/pages/Hive/components/StakeCard/styles.module.scss';
import { copyToClipboard } from '@/utils';

interface ContractAddressProps {
  title: string;
  value: string;
}

export const ContractAddress: FC<ContractAddressProps> = ({ title, value }) => {
  return (
    <li className={s.infoItem} key={`${title}-${value}`}>
      <Typography className={s.infoItemTitle} spacing={0} weight="medium" type="label2">
        {title}
      </Typography>
      <span className={s.infoItemRight}>
        <Typography
          color="accent"
          className={s.infoItemValue}
          spacing={0}
          weight="bold"
          type="label2"
        >
          {value}
        </Typography>
        <Button onClick={() => copyToClipboard(value)} variant="text">
          <CopyIcon width="12px" height="11px" />
        </Button>
      </span>
    </li>
  );
};
