import { FC, PropsWithChildren, ReactElement, useState } from 'react';
import cn from 'clsx';

import { ArrowIcon } from '@/assets/img/icons';

import { Button } from '../Button';
import { Typography } from '@/components';

import s from './styles.module.scss';

export interface ExpanderProps {
  header: ReactElement | string;
  variant?: 'default' | 'small';
  background?: 'white' | 'blue' | 'green';
  className?: string;
  contentClassName?: string;
  headerClassName?: string;
}

/**
 * @param {'white' | 'blue'} [background = 'white'] - background color
 * * white - #fff color
 * * blue - $darkBlue color
 * @param {'default' | 'small'} [variant = 'default'] - expander variant
 * * default - big expander with Expand/Collapse button
 * * small - small expander with only arrow icon
 * @param {string} [className] - the wrapper class name
 * @param {string} [contentClassName] - the expander content class name
 * @param {string} [headerClassName] - the expander headerClassName class name
 */
export const Expander: FC<PropsWithChildren<ExpanderProps>> = ({
  className,
  background = 'white',
  variant = 'default',
  header,
  children,
  contentClassName,
  headerClassName,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const handleToogle = () => {
    setIsOpened(!isOpened);
  };

  return (
    <section className={cn(s.expander, s[background], s[variant], className)}>
      <Button className={cn(s.button, s[background])} onClick={handleToogle} variant="text">
        {variant !== 'small' && (
          <Typography className={cn(s.buttonTitle)} type="label2">
            {isOpened ? 'COLLAPSE' : 'EXPAND'}
          </Typography>
        )}
        <ArrowIcon className={cn(s.buttonArrow, { [s.buttonArrowActive]: isOpened })} />
      </Button>
      <div className={cn(s.header, headerClassName)}>{header}</div>
      <div className={cn(s.content, isOpened && contentClassName, { [s.contentActive]: isOpened })}>
        {children}
      </div>
    </section>
  );
};
