import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, InfoPopover, Input } from '@/components';
import { DEFAULT_REFERRAL_CODE } from '@/config/constants';
import { useShallowSelector } from '@/hooks';
import { useWalletConnectorContext } from '@/services';
import {
  getIsUserRegistered,
  getMyReferralCode,
  getUserReferralNetwork,
  hitReferrer,
  register,
} from '@/store/referrals/actions';
import referralsActionTypes from '@/store/referrals/actionTypes';
import referralsSelector from '@/store/referrals/selectors';
import { claimReferralRewards } from '@/store/rewards/actions';
import rewardsActionTypes from '@/store/rewards/actionTypes';
import uiSelector from '@/store/ui/selectors';
import userSelector from '@/store/user/selectors';
import { Referral, RequestStatus } from '@/types';

import { MainInfo } from './components/MainInfo';
import { ReferralNetwork } from './components/ReferralNetwork';
import { ReferralRewards } from './components/ReferralRewards';
import { Statistic } from './components/Statistic';
import { sortReferrals } from './Referral.helpers';

import s from './Referrals.module.scss';

const Referrals: FC = () => {
  const dispatch = useDispatch();
  const address = useShallowSelector(userSelector.getProp('address'));
  const { isUserRegistered, referralNetwork, statistic, myCode, shortUrl } = useShallowSelector(
    referralsSelector.getReferrals,
  );
  const {
    [referralsActionTypes.REGISTER]: registerRequestStatus,
    [rewardsActionTypes.CLAIM_REFERRAL_REWARDS]: claimReferralRewardsRequestStatus,
  } = useShallowSelector(uiSelector.getUI);
  const { walletService } = useWalletConnectorContext();

  const [referrerCode, setReferrerCode] = useState(
    new URLSearchParams(window.location.search).get('ref') || '',
  );
  const [referrals, setReferrals] = useState(referralNetwork);

  const handleSortReferrals = (sortType: string, referralsArray: Array<Referral>) => {
    setReferrals(sortReferrals(sortType, referralsArray));
  };

  const handleRegisterUser = () => {
    dispatch(register({ web3Provider: walletService.Web3(), referrerCode }));
  };

  const handleClaimReferralRewards = () => {
    dispatch(claimReferralRewards({ web3Provider: walletService.Web3() }));
  };

  useEffect(() => {
    setReferrals(referralNetwork);
  }, [referralNetwork]);

  useEffect(() => {
    if (address) {
      dispatch(getIsUserRegistered({ web3Provider: walletService.Web3() }));
    }
  }, [address, dispatch, walletService]);

  useEffect(() => {
    if (address && isUserRegistered) {
      dispatch(getUserReferralNetwork());
      dispatch(getMyReferralCode());
    }
  }, [dispatch, isUserRegistered, address]);

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get('ref');
    if (code) {
      dispatch(hitReferrer({ code }));
    }
  }, [dispatch]);

  return (
    <section className={s.referrals_wrapper}>
      {isUserRegistered && (
        <MainInfo
          myCode={myCode}
          hits={statistic.pageVisitsCounter}
          createdAt={statistic.createdAt}
          shortLink={shortUrl}
        />
      )}
      <div className={s.section}>
        {!isUserRegistered && (
          <>
            <Input
              value={referrerCode}
              onChange={(newCode) => setReferrerCode(newCode.toUpperCase())}
              placeholder="XB3-AF0X2ZRQ9B4"
              label="Enter a Referral Code"
              className={s.inputWrapper}
              inputClassName={s.input}
              labelEndClassName={s.inputLabelEnd}
              labelEnd={
                <InfoPopover align="right">
                  Register your wallet with an existing referral code, to get your own unique
                  referral link and start your network. If you don&apos;t have a referral link,
                  use&nbsp;
                  <Button
                    onClick={() => setReferrerCode(DEFAULT_REFERRAL_CODE)}
                    className={s.codeButton}
                    variant="text"
                  >
                    {DEFAULT_REFERRAL_CODE}
                  </Button>
                  &nbsp;as a referral code.
                </InfoPopover>
              }
            />
            <Button
              loading={registerRequestStatus === RequestStatus.REQUEST}
              disabled={!referrerCode || !address}
              onClick={handleRegisterUser}
            >
              Register My Wallet
            </Button>
          </>
        )}
        {isUserRegistered && (
          <>
            <ReferralRewards
              isClaimingInProccess={claimReferralRewardsRequestStatus === RequestStatus.REQUEST}
              claimRewards={handleClaimReferralRewards}
              claimableRewards={statistic.rewards.claimable}
              pendingRewards={statistic.rewards.pending}
            />
            <Statistic statistic={statistic} />
          </>
        )}
      </div>
      <ReferralNetwork referrals={referrals} sortReferrals={handleSortReferrals} />
    </section>
  );
};
export default Referrals;
