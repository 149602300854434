import { createAction } from '@reduxjs/toolkit';

import {
  DepositCvxCrvReq,
  DepositReq,
  RequestWithWeb3Provider,
  UnwrapReq,
  WithdrawReq,
} from '@/types/requests';

import actionTypes from './actionTypes';

export const getVaultsData = createAction(actionTypes.GET_VAULTS_DATA);
export const depositLp = createAction<DepositReq>(actionTypes.DEPOSIT_LP);
export const depositCvxCrv = createAction<DepositCvxCrvReq>(actionTypes.DEPOSIT_CVX_CRV);
export const withdraw = createAction<WithdrawReq>(actionTypes.WITHDRAW);
export const claim = createAction<RequestWithWeb3Provider>(actionTypes.CLAIM);
export const unwrap = createAction<UnwrapReq>(actionTypes.UNWRAP);
