import { call, put, select, takeLatest } from 'typed-redux-saga';

import { signaturesApi } from '@/api/signatures';
import { error, request, success } from '@/store/api/actions';
import { updateUserState } from '@/store/user/reducer';
import userSelector from '@/store/user/selectors';

import { getUserSignatureInfo } from '../actions';
import actionTypes from '../actionTypes';

export function* getUserSignatureInfoSaga({ type }: ReturnType<typeof getUserSignatureInfo>) {
  yield put(request(type));

  try {
    const myAddress = yield* select(userSelector.getProp('address'));

    const { haveSignature } = yield* call(signaturesApi.getSignatureInfoByAddress, myAddress);

    yield* put(updateUserState({ isUserHaveSignature: haveSignature }));

    yield put(success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_USER_SIGNATURE_INFO, getUserSignatureInfoSaga);
}
