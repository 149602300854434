import { fork } from 'redux-saga/effects';

/* PLOP_INJECT_IMPORT_SAGA */
import hiveSaga from '@/store/hive/sagas';
import infoSaga from '@/store/info/sagas';
import lockupSaga from '@/store/lockup/sagas';
import ratesSaga from '@/store/rates/sagas';
import referralsSaga from '@/store/referrals/sagas';
import rewardsSaga from '@/store/rewards/sagas';
import userSaga from '@/store/user/sagas';

export default function* rootSaga() {
  yield fork(userSaga);
  /* PLOP_INJECT_FORK_SAGA */
  yield fork(hiveSaga);
  yield fork(referralsSaga);
  yield fork(infoSaga);
  yield fork(rewardsSaga);
  yield fork(lockupSaga);
  yield fork(ratesSaga);
}
