import { fork } from 'redux-saga/effects';

import getLockupDataSaga from './getLockupDataSaga';
import increaseLockupAmount from './increaseLockupAmount';
import increaseUnlockTime from './increaseUnlockTime';
import lockSaga from './lockSaga';
import unlockSaga from './unlockSaga';

export default function* lockupSagas() {
  yield fork(getLockupDataSaga);
  yield fork(lockSaga);
  yield fork(unlockSaga);
  yield fork(increaseLockupAmount);
  yield fork(increaseUnlockTime);
}
