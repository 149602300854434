import { call, put, select, takeLatest } from 'typed-redux-saga';

import { referralsApi } from '@/api/referralsApi';
import apiActions from '@/store/api/actions';
import userSelector from '@/store/user/selectors';

import { getUserReferralNetwork } from '../actions';
import actionTypes from '../actionTypes';
import { updateReferralsState } from '../reducer';

export function* getUserReferralNetworkSaga({ type }: ReturnType<typeof getUserReferralNetwork>) {
  yield* put(apiActions.request(type));

  try {
    const userAddress = yield* select(userSelector.getProp('address'));
    const referralNetworkNotFormatted = yield* call(referralsApi.getReferralNetwork, userAddress);
    const referralNetwork = referralNetworkNotFormatted.map((referralInfo) => ({
      ...referralInfo,
      createdAt: new Date(referralInfo.createdAt).valueOf().toString(),
    }));

    yield* put(updateReferralsState({ referralNetwork }));

    yield* put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_USER_REFERRAL_NETWORK, getUserReferralNetworkSaga);
}
