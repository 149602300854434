import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LockupState } from '@/types';

const initialState: LockupState = {
  totalLocked: '0',
  lockedApr: '0',
};

export const lockupReducer = createSlice({
  name: 'lockup',
  initialState,
  reducers: {
    updateLockupState: (state, action: PayloadAction<Partial<LockupState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateLockupState } = lockupReducer.actions;

export default lockupReducer.reducer;
