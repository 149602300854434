import { call, put, select, takeLatest } from 'typed-redux-saga';

import { ContractsNames } from '@/config';
import apiActions from '@/store/api/actions';
import userSelector from '@/store/user/selectors';
import { ConvexVaultAbi } from '@/types';
import { notify, toDecimals } from '@/utils';
import { getContractDataByHisName } from '@/utils/getContractDataByHisName';

import { withdraw } from '../actions';
import actionTypes from '../actionTypes';

import { getVaultsDataSaga } from './getVaultsData';

export function* withdrawSaga({
  type,
  payload: { web3Provider, vaultAddress, amount },
}: ReturnType<typeof withdraw>) {
  yield* put(apiActions.request(type));

  const [vaultAbi] = getContractDataByHisName(ContractsNames.convexVault);
  const userAddress = yield* select(userSelector.getProp('address'));
  try {
    const vaultContract: ConvexVaultAbi = yield new web3Provider.eth.Contract(
      vaultAbi,
      vaultAddress,
    );

    // CHECK IF CAN SEND
    yield* call(vaultContract.methods.withdraw(toDecimals(amount), userAddress).estimateGas, {
      from: userAddress,
    });

    yield* call(vaultContract.methods.withdraw(toDecimals(amount), userAddress).send, {
      from: userAddress,
    });

    yield* call(getVaultsDataSaga, {
      type: actionTypes.GET_VAULTS_DATA,
      payload: undefined,
    });

    yield* put(apiActions.success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    notify.error('Something may go wrong. Please check your inputs.');
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.WITHDRAW, withdrawSaga);
}
