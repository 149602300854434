import { call, put, select, takeLatest } from 'typed-redux-saga';

import { signaturesApi } from '@/api/signatures';
import { error, request, success } from '@/store/api/actions';
import userSelector from '@/store/user/selectors';

import { signTermsOfUse } from '../actions';
import actionTypes from '../actionTypes';
import { updateUserState } from '../reducer';

const MESSAGE_TO_SIGN =
  'Use of the xb3.finance website, services, dapp, or application is subject to the associated terms and conditions and I hereby confirm that I am aware of these and accept them in full. Over and above the referred to terms and conditions, I specifically confirm and accept the following: \n\n 1) xb3.finance is a smart contract protocol in alpha stage of launch, and even though multiple security audits have been completed on the smart contracts, I understand the risks associated with using the xb3.finance protocol and associated functions. \n\n 2) Any interactions that I have with the associated XB3 protocol apps, smart contracts or any related functions MAY place my funds at risk, and I hereby release the XB3 protocol and its contributors, team members, and service providers from any and all liability associated with my use of the abovementioned functions. \n\n 3) I am lawfully permitted to access this site and use the xb3.finance application functions, and I am not in contravention of any laws governing my jurisdiction of residence or citizenship. this site and use the xb3.finance application functions, and I am not in contravention of any laws governing my jurisdiction of residence or citizenship.';

export function* signTermsOfUseSaga({
  type,
  payload: { web3Provider },
}: ReturnType<typeof signTermsOfUse>) {
  yield put(request(type));
  const myAddress = yield* select(userSelector.getProp('address'));

  try {
    const signature = yield* call(web3Provider.eth.personal.sign, MESSAGE_TO_SIGN, myAddress, '');

    yield* call(signaturesApi.addSignedMessage, {
      address: myAddress,
      message: MESSAGE_TO_SIGN,
      signature,
    });

    yield put(updateUserState({ isUserHaveSignature: true }));

    yield put(success(type));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.SIGN_TERMS_OF_USE, signTermsOfUseSaga);
}
