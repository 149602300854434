import { INetwork } from '@amfi/connect-wallet/dist/interface';

import { Chains, IConnectWallet, IContracts } from '@/types';

import {
  bonusCampaignAbi,
  convexVaultAbi,
  curveVaultAbi,
  erc20Abi,
  inflationAbi,
  liquidityRouterAbi,
  lpTokenAbi,
  referralProgramAbi,
  treasuryAbi,
  veXb3TokenAbi,
  votingStakingRewardsAbi,
  xbe2Xb3MigratorAbi,
} from './abi';
import { isMainnet, mainnetRpc } from './constants';

export const chains: {
  [key: string]: {
    name: Chains;
    network: INetwork;
    provider: {
      [key: string]: { name: string };
    };
  };
} = {
  [Chains.Ethereum]: {
    name: Chains.Ethereum,
    network: {
      chainID: isMainnet ? 1 : 5,
      chainName: isMainnet ? 'Ethereum' : 'Goerli Testnet',
      rpc: isMainnet ? mainnetRpc : 'https://goerli.infura.io/v3/4d2ebfa160d54fddb43d5f09768914b6',
    },
    provider: {
      MetaMask: { name: 'MetaMask' },
    },
  },
};

export const connectWallet = (chainName: Chains): IConnectWallet => {
  const chain = chains[chainName];

  return {
    wallets: ['MetaMask'],
    network: chain.network,
    provider: chain.provider,
    settings: { providerType: true },
  };
};

// eslint-disable-next-line no-shadow
export enum ContractsNames {
  xb3Token = 'xb3Token',
  veXb3Token = 'veXb3Token',
  crvToken = 'crvToken',
  cvxToken = 'cvxToken',
  votingStakingRewards = 'votingStakingRewards',
  convexVault = 'convexVault',
  bonusCampaign = 'bonusCampaign',
  inflation = 'inflation',
  liquidityRouter = 'liquidityRouter',
  treasury = 'treasury',
  referralProgram = 'referralProgram',
  lpToken = 'lpToken',
  cvxCrvToken = 'cvxCrvToken',
  curveVault = 'curveVault',
  xbe2xb3Migrator = 'xbe2xb3Migrator',
}

export type IContractsNames = keyof typeof ContractsNames;

export const contractsConfig: IContracts = {
  names: Object.keys(ContractsNames),
  decimals: 18,
  contracts: {
    [ContractsNames.xb3Token]: {
      mainnet: {
        address: '0x56Bb5D139ECC91992587CedBA55794d7FEea489E',
        abi: erc20Abi,
      },
      testnet: {
        address: '0xaA19C8E5219E6f75e2D422caB7596F42d04acF01',
        abi: erc20Abi,
      },
    },
    [ContractsNames.veXb3Token]: {
      mainnet: {
        address: '0x2ED2b0880c931173d05080A0fd6E80Cf021BCaA0',
        abi: veXb3TokenAbi,
      },
      testnet: {
        address: '0x55c4Cba33eAAdEd885104668312BC24A4C72d8F8',
        abi: veXb3TokenAbi,
      },
    },
    [ContractsNames.crvToken]: {
      mainnet: {
        address: '0xD533a949740bb3306d119CC777fa900bA034cd52',
        abi: erc20Abi,
      },
      testnet: {
        address: '0xf60F2c157bf10F6A2DB32A5291dE182fC4eB951d',
        abi: erc20Abi,
      },
    },
    [ContractsNames.cvxToken]: {
      mainnet: {
        address: '0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B',
        abi: erc20Abi,
      },
      testnet: {
        address: '0xd829433aCc6a28b2B7350d61E772de5984FC17f3',
        abi: erc20Abi,
      },
    },
    [ContractsNames.cvxCrvToken]: {
      mainnet: {
        address: '0x62B9c7356A2Dc64a1969e19C23e4f579F9810Aa7',
        abi: erc20Abi,
      },
      testnet: {
        address: '0x2AbF7137875d9868527aa33941b87e388C708Dec',
        abi: erc20Abi,
      },
    },
    [ContractsNames.votingStakingRewards]: {
      mainnet: {
        address: '0xDf34257dB28E62DA75F17f7Af4F1EEAc6d268d16',
        abi: votingStakingRewardsAbi,
      },
      testnet: {
        address: '0x3571dF6DB24938e3af9f4edaB60CA4636C7fEa1E',
        abi: votingStakingRewardsAbi,
      },
    },
    [ContractsNames.convexVault]: {
      mainnet: {
        address: '0xb92588A832881783EEDc5498ff104bD61B05D2d4',
        abi: convexVaultAbi,
      },
      testnet: {
        address: '0xAdeBa637A2F201A8198Ead5B6d66576589633EB2',
        abi: convexVaultAbi,
      },
    },
    [ContractsNames.bonusCampaign]: {
      mainnet: {
        address: '0x5F36CeC289c9879bd4b055EFc3219E1cEd0F6adc',
        abi: bonusCampaignAbi,
      },
      testnet: {
        address: '0x1c54Db316C10049b3fb480Dae12416938486DC38',
        abi: bonusCampaignAbi,
      },
    },
    [ContractsNames.inflation]: {
      mainnet: {
        address: '0x57f2D75bd84bFb6912971713D0Fa326402A0aC95',
        abi: inflationAbi,
      },
      testnet: {
        address: '0xCA28440d02d4f8D69B7b3A0b9A122EBA9F0849Ef',
        abi: inflationAbi,
      },
    },
    [ContractsNames.liquidityRouter]: {
      mainnet: {
        address: '',
        abi: liquidityRouterAbi,
      },
      testnet: {
        address: '0xa6870C4Cbf78E42474a0BF65683dF3Cd08Aba134',
        abi: liquidityRouterAbi,
      },
    },
    [ContractsNames.treasury]: {
      mainnet: {
        address: '0x3f53F6728d99Df1E8d3e28452547A4E3A1742eF9',
        abi: treasuryAbi,
      },
      testnet: {
        address: '0x08958775d63764b6AcE129546fA1cDf459bBa738',
        abi: treasuryAbi,
      },
    },
    [ContractsNames.referralProgram]: {
      mainnet: {
        address: '0x5ED75f5df2dbD6F8751b61344286446bc247fdf2',
        abi: referralProgramAbi,
      },
      testnet: {
        address: '0xEC3fEa2A55888514F01126ea759E8fd645C34a68',
        abi: referralProgramAbi,
      },
    },
    [ContractsNames.lpToken]: {
      mainnet: {
        address: '0x9D0464996170c6B9e75eED71c68B99dDEDf279e8',
        abi: lpTokenAbi,
      },
      testnet: {
        address: '0x47D8797fa13D23C6CB5DA1cc6b435B7c5817Cfa5',
        abi: lpTokenAbi,
      },
    },
    [ContractsNames.curveVault]: {
      mainnet: {
        address: '0x9D0464996170c6B9e75eED71c68B99dDEDf279e8',
        abi: curveVaultAbi,
      },
      testnet: {
        address: '0x9D0464996170c6B9e75eED71c68B99dDEDf279e8',
        abi: curveVaultAbi,
      },
    },
    [ContractsNames.xbe2xb3Migrator]: {
      mainnet: {
        address: '0x7a68e3dfa9819DAA688D2e5DD9697EE93fbd1FBc',
        abi: xbe2Xb3MigratorAbi,
      },
      testnet: {
        address: '0x1b8e00f89cD0ee7427bDc3dF122b9a113f2be851',
        abi: xbe2Xb3MigratorAbi,
      },
    },
  },
};
export const networkDataForAddToMetamask = {
  chainId: isMainnet ? '0xfa' : '0xfa2',
  chainName: isMainnet ? 'Ethereum' : 'Goerli Testnet',
  rpc: [isMainnet ? mainnetRpc : 'https://goerli.infura.io/v3/4d2ebfa160d54fddb43d5f09768914b6'],
};
