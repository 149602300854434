import BigNumber from 'bignumber.js';

export const convertBigNumbersToReadable = (
  value: string | number,
  float = true,
  floatNum = 2,
): string => {
  const result = new BigNumber(value);
  if (result.isGreaterThanOrEqualTo(1_000_000_000_000_000)) {
    return `${result
      .dividedBy(1000000000000000)
      .toFormat(float ? floatNum : 0)
      .toString()}Q`;
  }
  if (result.isGreaterThanOrEqualTo(1_000_000_000_000)) {
    return `${result
      .dividedBy(1000000000000)
      .toFormat(float ? floatNum : 0)
      .toString()}T`;
  }
  if (result.isGreaterThanOrEqualTo(1_000_000_000)) {
    return `${result
      .dividedBy(1000000000)
      .toFormat(float ? floatNum : 0)
      .toString()}B`;
  }
  if (result.isGreaterThanOrEqualTo(1_000_000)) {
    return `${result
      .dividedBy(10000000)
      .toFormat(float ? floatNum : 0)
      .toString()}M`;
  }
  if (result.isGreaterThanOrEqualTo(100_000)) {
    return `${result
      .dividedBy(1000000)
      .toFormat(float ? floatNum : 0)
      .toString()}M`;
  }
  return result.toFormat(float ? floatNum : 0).toString();
};
