const GET_VAULTS_DATA = 'GET_VAULTS_DATA';
const DEPOSIT_LP = 'DEPOSIT_LP';
const DEPOSIT_CVX_CRV = 'DEPOSIT_CVX_CRV';
const WITHDRAW = 'WITHDRAW';
const CLAIM = 'CLAIM';
const UNWRAP = 'UNWRAP';

export default {
  GET_VAULTS_DATA,
  DEPOSIT_LP,
  DEPOSIT_CVX_CRV,
  WITHDRAW,
  CLAIM,
  UNWRAP,
};
