import api from '@/api/axios';
import { Referral, ReferralsStatistic, ReferrerBaseInfo } from '@/types';

export const referralsApi = {
  getReferrerFromCode: (code: string) => api.get<ReferrerBaseInfo>(`/referrals/from_code/${code}/`),
  hitReferrer: (code: string) => api.get(`/referrals/hit/${code}/`),
  getReferralCode: (userAddress: string) =>
    api.get<{ code: string; shortLink: '' }>(`/referrals/code/${userAddress}/`),
  getReferralNetwork: (userAddress: string) =>
    api
      .get<Array<Referral>>(`/referrals/my_network/${userAddress}/`)
      .then((res) => res.data)
      .catch(() => [] as Array<Referral>),
  getReferralStats: (userAddress: string) =>
    api
      .get<ReferralsStatistic | null>(`/referrals/stats/${userAddress}/`)
      .then((res) => res.data)
      .catch(() => null),
};
