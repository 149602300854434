const GET_LOCKUP_DATA = 'GET_LOCKUP_DATA';
const LOCK = 'LOCK';
const UNLOCK = 'UNLOCK';
const INCREASE_LOCKUP_AMOUNT = 'INCREASE_LOCKUP_AMOUNT';
const INCREASE_UNLOCK_TIME = 'INCREASE_UNLOCK_TIME';

export default {
  GET_LOCKUP_DATA,
  LOCK,
  UNLOCK,
  INCREASE_LOCKUP_AMOUNT,
  INCREASE_UNLOCK_TIME,
};
